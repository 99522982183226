import React from "react";
import "./amenitycard.scss";
import { bookingURL } from "../../helpers/paths";

const AmenityCard = ({
  img,
  title,
  desc,
  url = bookingURL,
  ctaText = "Book Now",
}) => {
  return (
    <>
      <div className="am_card">
        {img && (
          //   <Link to={url}>
          <div className="image_wrapper">
            <img
              width={584}
              height="100%"
              src={img}
              alt="amenity"
              className="am_img"
              loading="lazy"
            />
          </div>
          // </Link>
        )}
        <div className="am_text">
          {title && <h3 className="am_title">{title}</h3>}
          {desc && <p className="am_desc">{desc}</p>}
          {/* {url && (
            <div className="cta_wrapper">
              <div className="common_cta">{ctaText}</div>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default AmenityCard;
