import React, { useEffect, useRef } from "react";
import "./about.scss";
import { aboutBannerData, aboutValuesData } from "../../data/data";
import { images } from "../../source";
import { Link } from "react-router-dom";
import { bookingURL } from "../../helpers/paths";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ImageContentBox from "../../components/ImageContentBox/ImageContentBox";
import { useWindowSize } from "react-use";

const About = () => {
  // const [showVideo, setShowVideo] = useState(null);
  // const [isMuted, setIsMuted] = useState(false);
  const section2Ref = useRef(null);
  const { width } = useWindowSize();

  // const videoHandle = (i) => {
  //   setShowVideo(i);
  //   setIsMuted(true);
  // };

  // const soundHandle = () => {
  //   setIsMuted((prevIsMuted) => !prevIsMuted);
  // };

  const scrollToRef = (ref, offset) => {
    if (ref && ref.current) {
      const top = ref.current.offsetTop - offset;
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    }
  };

  const aboutBanner = aboutBannerData.map((data, i) => (
    <React.Fragment key={i}>
      <div className="banner_wrap">
        <div className="video_banner">
          {/* {!showVideo && ( */}
          <div className="thumbnail_wrapper">
            <img
              width={1920}
              height={1080}
              src={width > 767 ? data.banImg : data.mbBanImg}
              alt={data.alt}
              className="banner_img"
            />
            {/* <img
                  width={95}
                  height={95}
                  src={playIcon}
                  alt="play icon"
                  className="play_icon"
                  onClick={() => videoHandle(data.id)}
                /> */}
          </div>
          {/* )} */}
          {/* {showVideo && (
                <video
                  width={1920}
                  height={1080}
                  autoPlay
                  loop
                  muted={isMuted ? 0 : 1}
                  playsinline
                  className="w-full h-full object-cover upgrad_video"
                  onClick={soundHandle}
                >
                  <source src={data.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )} */}
        </div>
      </div>
    </React.Fragment>
  ));

  const valueList = aboutValuesData.map((data, i) => (
    <React.Fragment key={i}>
      <div className="box" data-aos="fade-up" data-aos-delay={i * 100}>
        <div className="image_title_wrap">
          <img
            width={480}
            height={587}
            src={width > 767 ? data.img : data.mbImg}
            alt={images.value01.alt}
            className="back_img"
            loading="lazy"
          />
          <h3 className="box_title">{data.title}</h3>
        </div>
        <div className="box_content">
          <p className="box_description">{data.description}</p>
        </div>
      </div>
    </React.Fragment>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="about_sec1" data-aos="fade">
        <div className="my_container">
          <Breadcrumb text1="About Us" disable />
          <img
            width={42}
            height={33}
            src={images.scrollDown.image}
            alt={images.scrollDown.alt}
            className="scroll_down"
            loading="lazy"
            onClick={() => scrollToRef(section2Ref, 100)}
          />
        </div>
        <div className="my_container full_width_container zero_padding">
          {aboutBanner}
        </div>
      </section>

      <div className="my_container">
        <section className="about_sec2" ref={section2Ref}>
          <div className="about_hd_sec">
            <h2 className="section_title vert_horizon_line" data-aos="fade-up">
              Behind <span className="bold_text">UpGrad Living</span>
            </h2>
            <p
              className="section_description"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              upGrad Living, where your next chapter begins with comfort,
              community, and convenience. At the heart of our mission is a
              simple yet profound promise: to offer a home away from home for
              students embarking on their academic journeys. We understand that
              finding the perfect student accommodation is about more than just
              a place to stay; it’s about creating a foundation for success,
              both academically and personally.
            </p>
          </div>
        </section>
      </div>

      <section className="about_sec3">
        <div className="my_container full_width_container zero_padding">
          <ImageContentBox
            img={images.about01.image}
            mbImg={images.mbAbout01.image}
            title="Our"
            boldTitle="Vision"
            description="We envision a world where every student has access to accommodation that not only meets their basic needs but also enriches their university experience. Our property is designed with the modern student in mind, offering a blend of privacy, study areas, social spaces, and all the amenities necessary for a balanced lifestyle. We believe that a great living environment can inspire learning, foster friendships, and support personal growth."
          />
        </div>
      </section>

      <section className="about_sec4">
        <div className="my_container">
          <h2 className="section_64title" data-aos="fade-up">
            Our <span className="bold_text">Values</span>
          </h2>
        </div>
        <div className="my_container full_width_container zero_padding">
          <div className="row-1">{valueList}</div>
        </div>
      </section>

      <section className="about_sec5">
        <div className="my_container">
          <h2 className="section_title vert_horizon_line" data-aos="fade-up">
            Our <span className="bold_text">Accommodation</span>
          </h2>
        </div>
      </section>
      <div className="my_container full_width_container zero_padding">
        <ImageContentBox
          img={images.about02.image}
          mbImg={images.mbAbout02.image}
          description="From cozy rooms to spacious shared dorms, our range of accommodations is tailored to suit diverse needs and budgets. Each property is strategically located to ensure easy access to universities, shopping, dining, and entertainment options. With high-speed internet, modern appliances, and stylish furnishings, our homes are ready to move in and start living."
          // ctaUrl={aboutURL}
          // ctaText="Know more"
        />
      </div>

      <div className="my_container">
        <section className="about_sec6">
          <div className="text_container text_container1">
            <h2
              className="section_title vert_horizon_line"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Why <span className="bold_text">Choose Us?</span>
            </h2>
            <p className="section_description" data-aos="fade-up">
              Choosing upGrad Living means more than just finding a place to
              live; it’s about enhancing your university experience. Our vibrant
              communities, supportive staff, and commitment to excellence set us
              apart. Whether you’re an undergraduate, postgraduate, or
              international student, we’re here to make your transition to
              university life as smooth and enjoyable as possible.
            </p>
          </div>
          <div className="text_container text_container2">
            <h2
              className="section_title vert_horizon_line"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Join <span className="bold_text">Our Community</span>
            </h2>
            <p className="section_description" data-aos="fade-up">
              Ready to find your home away from home? Explore our accommodations
              and discover why students from around the world choose upGrad
              Living for their student accommodation needs. Let us be a part of
              your journey to success.
            </p>
            <div
              className="cta_wrapper"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <Link
                to={bookingURL}
                className="common_cta book_now_cta"
                // target="_blank"
                // rel="noreferrer"
              >
                Book Now
              </Link>
              <a
                to="tel:+919004106218"
                className="common_cta book_now_cta"
                target="_blank"
                rel="noreferrer"
              >
                Call Now
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default About;
