import React, { useEffect } from "react";
import "./gallery.scss";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import { galleryBanner, mbGalleryBanner } from "../../source";
import { useWindowSize } from "react-use";
import { galleryData } from "../../data/galleryData";

const Gallery = () => {
  const { width } = useWindowSize();
  // const galleryLength = galleryData[0].imageList.length;
  // console.log("length is", galleryLength);
 

  const galleryList = galleryData.map((item, i) => (
    // <div className="gallery_row">
    //   <div className="gallery_column">
    //     <img src={item.img1} alt={item.alt1} className="gallery_img" />
    //   </div>
    //   <div className="gallery_column">
    //     <img src={item.img2} alt={item.alt2} className="gallery_img" />
    //   </div>
    //   <div className="gallery_column">
    //     <img src={item.img3} alt={item.alt3} className="gallery_img" />
    //   </div>
    //   <div className="gallery_column">
    //     <img src={item.img4} alt={item.alt4} className="gallery_img" />
    //   </div>
    // </div>
    <div className="gallery_row" key={i} data-aos="fade-up">
      {item.imageList?.map((gallery, index) => (
        <div
          className={`gallery_column ${
            galleryData[i].imageList.length <= 3 ? "gallery_three_column" : ""
          }`}
          key={index}
        >
          <img src={width > 767 ? gallery.img : gallery.mbImg} alt={gallery.alt} className="gallery_img" />
        </div>
      ))}
    </div>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="gallery_banner_sec" data-aos="fade">
        <CommonBanner
          text1="Gallery"
          disable
          title="GALLERY"
          image={width > 767 ? galleryBanner : mbGalleryBanner}
        />
      </section>

      <section className="gallery_sec1">
        <div className="my_container">
          <h2 className="section_title vert_horizon_line" data-aos="fade-up">
            OUR GALLERY
            <br />
            <span className="bold_text">Never a Dull Moment</span>
          </h2>
        </div>
      </section>

      <section className="gallery_sec2">
        {galleryList}
        {/* <div className="btn_wrapper" data-aos="fade-up">
          <button className="button_wrapper">Load more</button>
        </div> */}
      </section>
    </>
  );
};

export default Gallery;
