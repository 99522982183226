import { images } from "../source";

export const galleryData = [
  // {
  //     id: 1,
  //     img1: images.gallery01.image,
  //     alt1: images.gallery01.alt,
  //     img2: images.gallery02.image,
  //     alt2: images.gallery02.alt,
  //     img3: images.gallery03.image,
  //     alt3: images.gallery03.alt,
  //     img4: images.gallery04.image,
  //     alt4: images.gallery04.alt,
  // },
  {
    id: 1,
    imageList: [
      {
        id: 1,
        img: images.gallery01.image,
        mbImg: images.mbGallery01.image,
        alt: images.gallery01.alt,
      },
      {
        id: 2,
        img: images.gallery02.image,
        mbImg: images.mbGallery02.image,
        alt: images.gallery02.alt,
      },
      {
        id: 3,
        img: images.gallery03.image,
        mbImg: images.mbGallery03.image,
        alt: images.gallery03.alt,
      },
      {
        id: 4,
        img: images.gallery04.image,
        mbImg: images.mbGallery04.image,
        alt: images.gallery04.alt,
      },
    ],
  },
  {
    id: 2,
    imageList: [
      {
        id: 1,
        img: images.gallery05.image,
        mbImg: images.mbGallery05.image,
        alt: images.gallery05.alt,
      },
      {
        id: 2,
        img: images.gallery06.image,
        mbImg: images.mbGallery06.image,
        alt: images.gallery06.alt,
      },
      {
        id: 3,
        img: images.gallery07.image,
        mbImg: images.mbGallery07.image,
        alt: images.gallery07.alt,
      },
      {
        id: 4,
        img: images.gallery08.image,
        mbImg: images.mbGallery08.image,
        alt: images.gallery08.alt,
      },
    ],
  },
  {
    id: 3,
    imageList: [
      {
        id: 1,
        img: images.gallery09.image,
        mbImg: images.mbGallery09.image,
        alt: images.gallery09.alt,
      },
      {
        id: 2,
        img: images.gallery10.image,
        mbImg: images.mbGallery10.image,
        alt: images.gallery10.alt,
      },
      {
        id: 3,
        img: images.gallery11.image,
        mbImg: images.mbGallery11.image,
        alt: images.gallery11.alt,
      },
      {
        id: 4,
        img: images.gallery12.image,
        mbImg: images.mbGallery12.image,
        alt: images.gallery12.alt,
      },
    ],
  },
  {
    id: 4,
    imageList: [
      {
        id: 1,
        img: images.gallery13.image,
        mbImg: images.mbGallery13.image,
        alt: images.gallery13.alt,
      },
      {
        id: 2,
        img: images.gallery14.image,
        mbImg: images.mbGallery14.image,
        alt: images.gallery14.alt,
      },
      {
        id: 3,
        img: images.gallery15.image,
        mbImg: images.mbGallery15.image,
        alt: images.gallery15.alt,
      },
      {
        id: 4,
        img: images.gallery16.image,
        mbImg: images.mbGallery16.image,
        alt: images.gallery16.alt,
      },
    ],
  },
];
