import React, { useEffect } from "react";
import "./whyupgrandliving.scss";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import {
  images,
  mbWhyUpgradLivingBanner,
  whyUpgradLivingBanner,
} from "../../source";
import { useWindowSize } from "react-use";
import ImageContentBox from "../../components/ImageContentBox/ImageContentBox";
import {
  amenitiesURL,
  bookingURL,
  exploreAroundYouURL,
} from "../../helpers/paths";

const WhyUpgrandLiving = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="whyupgrad_banner_sec" data-aos="fade">
        <CommonBanner
          text1="Why upGrad Living"
          disable
          title="WHY <br /> upGrad LIVING"
          image={width > 767 ? whyUpgradLivingBanner : mbWhyUpgradLivingBanner}
        />
      </section>

      <section className="whyupgrad_sec1" data-aos="fade-up">
        <div className="my_container">
          <h2 className="section_title vert_horizon_line" data-aos="fade-up">
            upGrad living:
            <br />
            <span className="bold_text">The one stop choice</span>
          </h2>
          <div className="desc_wrapper" data-aos="fade-up">
            <p className="section_description">
              upGrad Living offers a unique and holistic living experience for
              ATLAS students in the bustling heart of Mumbai. This page is
              dedicated to showcasing the exceptional facilities, prime
              location, comprehensive services, and world-class amenities that
              make upGrad Living the ideal choice for students seeking a
              comfortable, convenient, and community-oriented living space.
            </p>
          </div>
        </div>
      </section>

      <section className="whyupgrad_sec3">
        <ImageContentBox
          img={images.whyUpgradLiving02.image}
          mbImg={images.mbWhyUpgradLiving02.image}
          title="Location"
          //   boldTitle="Services"
          description="Nestled in Sunteck BKC 51, LBS Marg, Kurla West, upGrad Living is situated in a vibrant area of Mumbai known for its dynamic cityscape and office dwellers. The location offers easy access to the city’s various educational institutions, cultural hotspots, and entertainment venues, making it an ideal spot for students who want to balance their academic life with social and cultural exploration."
          ctaUrl={exploreAroundYouURL}
          ctaText="Explore"
          // layout2
        />
      </section>

      <section className="whyupgrad_sec2">
        <ImageContentBox
          img={images.whyUpgradLiving01.image}
          mbImg={images.mbWhyUpgradLiving01.image}
          title="Facilities"
          //   boldTitle="Services"
          description="upGrad Living is designed with the modern student in mind, offering a range of facilities that cater to both academic and personal needs. The living spaces are optimized for comfort and functionality, featuring ergonomic furniture, climate control systems, and soundproofing to ensure a peaceful environment for studying and relaxation. The design of each unit provides privacy and includes ample storage solutions, allowing students to personalize their space and keep their belongings organized."
          ctaUrl={bookingURL}
          ctaText="Book Now"
          ctaClass="book_now_cta"
          // newTab
          layout2
        />
      </section>

      <section className="whyupgrad_sec4">
        <ImageContentBox
          img={images.whyUpgradLiving03.image}
          mbImg={images.mbWhyUpgradLiving03.image}
          title="Services"
          //   boldTitle="Services"
          description="upGrad Living goes beyond just accommodation by providing a suite of services designed to make student life as seamless as possible. The upGrad Living app enhances the living experience with features like event booking, roommate connections, community amenity access, and streamlined approval requests. These services ensure that students have everything they need at their fingertips."
          ctaUrl={bookingURL}
          ctaText="Book Now"
          ctaClass="book_now_cta"
          // newTab
        />
      </section>
     
      <section className="whyupgrad_sec5">
        <ImageContentBox
          img={images.whyUpgradLiving04.image}
          mbImg={images.mbWhyUpgradLiving04.image}
          title="Amenities"
          //   boldTitle="Services"
          description="The community amenities at upGrad Living are second to none. Students have access to social hubs, lounges, study areas, and recreation rooms, all designed to foster interaction and a sense of belonging. Outdoor areas provide a refreshing space for relaxation and socializing, while the overall design of the living spaces reflects the cultural context of Mumbai and the university community."
          ctaUrl={amenitiesURL}
          ctaText="Know more"
          ctaUrl1={bookingURL}
          ctaText1="Book Now"
          ctaClass1="book_now_cta"
          // newTab1
          layout2
        />
      </section>

      <section className="whyupgrad_sec6" data-aos="fade-up">
        <div className="my_container">
          <div className="content_wrapper">
            <p className="section_description vert_horizon_line">
              upGrad Living is not just a place to stay; it’s a lifestyle choice
              for students who value convenience, comfort, and community. With
              its thoughtful design, comprehensive services, and commitment to
              student well-being, upGrad Living stands out as the premier choice
              for student accommodation in Mumbai.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyUpgrandLiving;
