import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import HeaderNew from "./components/Header/Header";
import {
  aboutURL,
  accommodationURL,
  amenitiesURL,
  bookingURL,
  diningURL,
  exploreAroundYouURL,
  homeURL,
  studentLifeURL,
  thankyouURL,
  whyUpgrandLivingURL,
  galleryURL,
  contactURL,
  termsandconditionURL,
  privacypolicyURL,
} from "./helpers/paths";
import Homepage from "./pages/Homepage/Homepage";
import NotFound from "./pages/NotFound/NotFound";
import Thankyoupage from "./pages/Thankyou/Thankyou";
import About from "./pages/About/About";
import Amenities from "./pages/Amenities/Amenities";
import { useWindowSize } from "react-use";
import Accommodation from "./pages/Accommodation/Accommodation";
import Dining from "./pages/Dining/Dining";
import WhyUpgrandLiving from "./pages/WhyUpgrandLiving/WhyUpgrandLiving";
import ExploreAroundYou from "./pages/ExploreAroundYou/ExploreAroundYou";
import Gallery from "./pages/Gallery/Gallery";
import StudentLife from "./pages/StudentLife/StudentLife";
import Contact from "./pages/Contact/Contact";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

const Routing = () => {
  const { width } = useWindowSize();

  return (
    <>
      <HeaderNew />
      <Routes>
        <Route path={homeURL} element={<Homepage />} />
        <Route path={aboutURL} element={<About />} />
        <Route path={amenitiesURL} element={<Amenities />} />
        <Route path={diningURL} element={<Dining />} />
        <Route path={studentLifeURL} element={<StudentLife />} />
        <Route path={termsandconditionURL} element={<TermsAndConditions />} />
        <Route path={privacypolicyURL} element={<PrivacyPolicy />} />
        <Route path={thankyouURL} element={<Thankyoupage />} />
        <Route path={accommodationURL} element={<Accommodation />} />
        <Route path={whyUpgrandLivingURL} element={<WhyUpgrandLiving />} />
        <Route path={exploreAroundYouURL} element={<ExploreAroundYou />} />
        <Route path={galleryURL} element={<Gallery />} />
        <Route path={contactURL} element={<Contact />} />
        <Route path="/book-now" element={<Navigate to={homeURL} />} />
        <Route path="/undefined" element={<Navigate to={homeURL} />} />
        <Route path="life-at-atlas" element={<Navigate to={homeURL} />} />
        <Route exact path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <Footer />
      {width < 600 && (
        <div className="sticky_booknow">
          <a
            href={bookingURL}
            className="common_cta"
            // target="_blank"
            // rel="noreferrer"
          >
            BOOK NOW
          </a>
          <a
            href="tel:+919004106218"
            className="common_cta"
            // target="_blank"
            // rel="noreferrer"
          >
            CALL NOW
          </a>
        </div>
      )}
    </>
  );
};

export default Routing;
