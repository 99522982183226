import { images } from "../source";

export const exploreAroundYouData = [
  {
    id: 1,
    type: "single image",
    img: images.explore00.image,
    mbImg: images.exploreMb00.image,
    alt: images.explore00.alt,
    title:
      "Directly opposite to your university, situated <strong>0.00 kilometers</strong> away from your new home",
    boldTitle: "",
    connect: [],
    titlClass: "content_title_univ"
  },
  {
    id: 2,
    type: "double image",
    img: images.explore01_01.image,
    mbImg: images.exploreMb01_01.image,
    alt: images.explore01_01.alt,
    img1: images.explore01_02.image,
    mbImg1: images.exploreMb01_02.image,
    alt1: images.explore01_02.alt,
    title: "Seamless",
    boldTitle: "Conectivity",
    connect: [
      {
        id: 1,
        heading: "Kurla Local Railway Station",
        desc: "1 Km",
      },
      {
        id: 2,
        heading: "Proposed BKC Metro Station",
        desc: "1 Km",
      },
      {
        id: 3,
        heading: "Nearest intercity Railway Station",
        desc: "2.9 Km",
      },
      {
        id: 4,
        heading: "Mumbai International Airport (BOM)",
        desc: "9 Km",
      },
    ],
  },
  {
    id: 3,
    type: "single image",
    img: images.explore02.image,
    mbImg: images.exploreMb02.image,
    alt: images.explore02.alt,
    title: "A world of",
    boldTitle: "delicacies",
    connect: [
      {
        id: 1,
        heading: "Foodcourt, Jio world",
        desc: "1.8 km",
      },
      {
        id: 2,
        heading: "Foodcourt Phoenix Marketcity",
        desc: "2.3 kms",
      },
      {
        id: 3,
        heading: "Foodhall Linking Road",
        desc: "7.3 kms",
      },
    ],
  },
  {
    id: 4,
    type: "double image",
    img: images.explore03_01.image,
    mbImg: images.exploreMb03_01.image,
    alt: images.explore03_01.alt,
    img1: images.explore03_02.image,
    mbImg1: images.exploreMb03_02.image,
    alt1: images.explore03_02.alt,
    title: "Spend Fun-Filled",
    boldTitle: "Leisure Hours",
    connect: [
      {
        id: 1,
        heading: "Smash Utopia",
        desc: "13.9 kms",
      },
      {
        id: 2,
        heading: "Jio World Drive-in Theatre",
        desc: "3.5 kms",
      },
    ],
  },
  {
    id: 5,
    type: "single image",
    img: images.explore04.image,
    mbImg: images.exploreMb04.image,
    alt: images.explore04.alt,
    title: "Heritage and",
    boldTitle: "Culture",
    connect: [
      {
        id: 1,
        heading: "Kalaghoda",
        desc: "17.7 kms",
      },
      {
        id: 2,
        heading: "Siddhivinayak temple",
        desc: "10.3 kms",
      },
      {
        id: 3,
        heading: "Gateway of India",
        desc: "21 kms",
      },
      {
        id: 4,
        heading: "Marine Drive",
        desc: "20 kms",
      },
    ],
  },
  {
    id: 6,
    type: "double image",
    img: images.explore05_01.image,
    mbImg: images.exploreMb05_01.image,
    alt: images.explore05_01.alt,
    img1: images.explore05_02.image,
    mbImg1: images.exploreMb05_02.image,
    alt1: images.explore05_02.alt,
    title: "Medical Emergency?",
    boldTitle: "Don’t Freak Out!",
    connect: [
      {
        id: 1,
        heading: "Asian Heart Institute",
        desc: "2 kms",
      },
      {
        id: 2,
        heading: "Lilavati Hospital",
        desc: "4 kms",
      },
      {
        id: 3,
        heading: "Fortis Hospital",
        desc: "7 kms",
      },
      {
        id: 4,
        heading: "Hinduja Hospital",
        desc: "9 kms",
      },
    ],
  },
  {
    id: 7,
    type: "single image",
    img: images.explore06.image,
    mbImg: images.exploreMb06.image,
    alt: images.explore06.alt,
    title: "Shop till",
    boldTitle: "you drop",
    connect: [
      {
        id: 1,
        heading: "Jio World Center",
        desc: "1.8 km",
      },
      {
        id: 2,
        heading: "Phoenix Marketcity Kurla",
        desc: "2.3 kms",
      },
      {
        id: 3,
        heading: "Hill Road",
        desc: "6 kms",
      },
      {
        id: 4,
        heading: "Linking Road",
        desc: "7 kms",
      },
      {
        id: 5,
        heading: "Colaba Causeway",
        desc: "25 kms",
      },
    ],
  },
  {
    id: 8,
    type: "double image",
    img: images.explore07_01.image,
    mbImg: images.explore07_01.image,
    alt: images.exploreMb07_01.alt,
    img1: images.explore07_02.image,
    mbImg1: images.exploreMb07_02.image,
    alt1: images.explore07_02.alt,
    title: "Mountain or Hiking!",
    boldTitle: "What’s your thing?",
    connect: [
      {
        id: 1,
        heading: "Borivali National Park",
        desc: "22 kms",
      },
      {
        id: 2,
        heading: "Alibaug",
        desc: "97.4 kms",
      },
      {
        id: 3,
        heading: "Gateway of India",
        desc: "21 kms",
      },
      {
        id: 4,
        heading: "Lonavala",
        desc: "82 kms",
      },
    ],
  },
];
