import React, { useEffect } from "react";
import "./accommodation.scss";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import {
  accommodationBanner,
  mbAccommodationBanner,
  restRoom,
} from "../../source";
import { useWindowSize } from "react-use";
import { accommodationData, restRoomData } from "../../data/accommodationData";

const Accommodation = () => {
  const { width } = useWindowSize();

  const accomodationList = accommodationData.map((item, i) => (
    <div className="row accomm_row" key={i}>
      <div
        className={`col-md-12 col-lg-6 zero_padding ${
          i % 2 === 0 ? "order-lg-1" : "order-lg-2"
        }`}
        data-aos="fade-up"
      >
        <img
          width={width > 767 ? 960 : 375}
          height={width > 767 ? 538 : 380}
          src={width > 767 ? item.img : item.mbImg}
          alt={item.alt}
          className="box_img"
          loading="lazy"
        />
      </div>
      <div
        className={`col-md-12 col-lg-6 zero_padding ${
          i % 2 === 0 ? "order-lg-2" : "order-lg-1"
        }`}
        data-aos="fade-up"
      >
        <div className={`box_text ${i % 2 === 0 ? "left_box" : "right_box"}`}>
          {/* <h3 className="box_title">
            {item.title} <span className="bold_text">{item.boldTitle}</span>
          </h3> */}
           <h2 className="acc_title">{item.title}</h2>
          <ul className="highlights_list">
            {item.highlights?.map((highlights, i) => (
              <li className="list_item" key={i}>
                <img
                  width={30}
                  height={30}
                  src={highlights.icon}
                  alt=""
                  className="icon"
                />
                <p className="highlight_point">{highlights.title}</p>
              </li>
            ))}
          </ul>
          <p
            className="acc_desc"
            data-aos-delay="200"
            dangerouslySetInnerHTML={{ __html: item.description}}
          >
          </p>
          {/* <p
            className="section_description box_description"
            data-aos-delay="200"
          >
            {item.description}
          </p> */}
          <div className="cta_wrapper" data-aos-delay="200">
            <a
              href={item.link}
              className="common_cta book_now_cta"
              // target="_blank"
              // rel="noreferrer"
            >
              Book Now
            </a>
            <a
              href="tel:+919004106218"
              className="common_cta book_now_cta"
              // target="_blank"
              rel="noreferrer"
            >
              Call Now
            </a>
          </div>
        </div>
      </div>
    </div>
  ));

  const restRoomList = restRoomData.map((item, i) => (
    <li>
      <img
        src={item.img}
        alt={item.alt}
        className={`rest_list_img ${item.imgClass ? item.imgClass : ""}`}
      />
      <p className="restroom_text">{item.list}</p>
    </li>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="accom_banner_sec" data-aos="fade">
        <CommonBanner
          text1="Accommodation"
          disable
          title="ACCOMMODATION"
          image={width > 767 ? accommodationBanner : mbAccommodationBanner}
        />
      </section>
      <section className="accom_sec1" data-aos="fade-up">
        <div className="my_container">
          <h2 className="section_title vert_horizon_line" data-aos="fade-up">
            The Feeling of Home
            <br />
            <span className="bold_text">In the heart of Mumbai</span>
          </h2>
          <div className="desc_wrapper" data-aos="fade-up">
            <p className="section_description">
              At upGrad Living, we understand that a comfortable and secure
              living space is crucial for students to thrive academically and
              socially. That’s why we’ve designed our accommodation to be more
              than just a place to stay—it’s a community where students can
              live, study, and relax in a contemporary and supportive
              environment.
            </p>
            <p className="section_description">
              Our shared spaces are crafted with a modern European flair,
              offering style and functionality. We offer a variety of
              room-sharing options to suit different preferences and budgets:
            </p>
          </div>
        </div>
      </section>

      <section className="accom_sec2" data-aos="fade-up">
        <div className="box_layout_01">{accomodationList}</div>
      </section>

      <section className="accom_sec3" data-aos="fade-up">
        <div className="my_container">
          <div className="content_wrapper">
            <p className="section_description">
              Each room is equipped with individual storage areas, study tables,
              and chairs, as well as dedicated shoe storage spaces. We
              prioritize the safety and security of our residents with separate
              dorm areas for male and female students, restricted floor access,
              and round-the-clock security personnel.
            </p>
          </div>
        </div>
      </section>

      <section className="accom_sec4" data-aos="fade-up">
        <div className="row restrooms_row">
          <div className="col-md-12 col-lg-6 restrooms_col" data-aos="fade-up">
            <div className="image_wrapper">
              <img
                src={restRoom}
                alt="restroom"
                className="rest_img"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 restrooms_col" data-aos="fade-up">
            <div className="conent_wrapper">
              <h2 className="heading">Restrooms</h2>
              <p className="section_description">
                Understanding the importance of hygiene, our communal restrooms
                are maintained to the highest standards.
              </p>
              <ul className="restroom_lists_wrapper">{restRoomList}</ul>
              {/* <div className="cta_wrapper">
                <Link to={() => false} className="common_cta">
                  Book Now
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Accommodation;
