import React, { useEffect } from "react";
import "./explorearoundyou.scss";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import { images, locationIcon } from "../../source";
import { useWindowSize } from "react-use";
import { exploreAroundYouData } from "../../data/exploreAroundYouData";

const ExploreAroundYou = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const exploreList = exploreAroundYouData.map((item, i) => (
    <div className="row explore_row" key={i}>
      <div
        className={`col-lg-6 zero_padding ${
          i % 2 === 0 ? "order-lg-1" : "order-lg-2"
        }`}
      >
        {item.type === "double image" ? (
          <div className="image_box_1" data-aos="fade-up">
            <img
              src={width > 767 ? item.img : item.mbImg}
              alt={item.alt}
              width={width > 767 ? 468 : 183}
              height={width > 767 ? 553 : 216}
              className="box1_img"
              loading="lazy"
            />
            <img
              src={width > 767 ? item.img1 : item.mbImg1}
              alt={item.alt1}
              width={width > 767 ? 468 : 183}
              height={width > 767 ? 553 : 216}
              className="box1_img"
              loading="lazy"
            />
          </div>
        ) : (
          <div className="image_box_2" data-aos="fade-up">
            <img
              src={width > 767 ? item.img : item.mbImg}
              alt={item.alt}
              width={width > 767 ? 960 : 375}
              height={width > 767 ? 553 : 216}
              className="box2_img"
              loading="lazy"
            />
          </div>
        )}
      </div>
      <div
        className={`col-lg-6 zero_padding ${
          i % 2 === 0 ? "order-lg-2" : "order-lg-1"
        }`}
      >
        <div
          className={`content_box ${i % 2 !== 0 ? "left_box" : ""} ${
            item.connect.length === 0 ? "content_box_1" : ""
          }`}
        >
          {item.connect.length === 0 ? (
            <h3
              className={`content_title ${
                item.titlClass ? item.titlClass : ""
              }`}
              data-aos="fade-up"
              data-aos-delay="100"
              dangerouslySetInnerHTML={{ __html: item.title}}
            ></h3>
          ) : (
            <h3
              className="content_title"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {item.title} <span className="bold_text">{item.boldTitle}</span>
            </h3>
          )}
          {item.connect?.map((loc, i) => (
            <div
              className="connectivity_wrapper"
              key={i}
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="details_box">
                <img
                  src={locationIcon}
                  alt="location"
                  className="details_icon"
                />
                <h4 className="heading">{loc.heading}</h4>
              </div>
              <div className="distance_wrapper">
                <h6 className="distance">{loc.desc}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ));
  return (
    <>
      <section className="explore_banner_sec" data-aos="fade">
        <CommonBanner
          text1="Why upGrad Living"
          disable
          title="EXPLORE <br/> AROUND YOU"
          image={
            width > 767
              ? images.exploreBanner.image
              : images.mbExploreBanner01.image
          }
        />
      </section>

      <section className="explore_sec1">
        <div className="my_container">
          <h2 className="section_title vert_horizon_line" data-aos="fade-up">
            Explore Around
            <br />
            <span className="bold_text">Amazing Mumbai</span>
          </h2>
        </div>
      </section>

      <section className="explore_sec2">{exploreList}</section>
    </>
  );
};

export default ExploreAroundYou;
