export { default as whitearrow } from "./images/general/white-arrow.svg";
export { default as circle } from "./images/general/circle.svg";
export { default as wctaarrow } from "./images/general/Polygon-3.svg";
export { default as downarrow } from "./images/general/Polygon-1.svg";
export { default as dummyvideo } from "./video/demo-video.mp4";
export { default as bannerVideo } from "./video/upgrad-banner.mp4";
export { default as bannerVideoMb } from "./video/upgrad-banner-mb.mp4";
export { default as downarrowcircle } from "./images/general/down-arrow.svg";
export { default as redarrow } from "./images/general/red-arrow.svg";
export { default as blackarrow } from "./images/general/black-arrow.svg";
export { default as greyarrow } from "./images/general/grey-arrow.svg";
export { default as rightcirclearrow } from "./images/general/right-arrow.svg";
export { default as leftcirclearrow } from "./images/general/left-arrow.svg";
export { default as pinkpatch } from "./images/general/count_icon_patch.svg";
export { default as instaicon } from "./images/general/insta-icon.svg";
export { default as scrollup } from "./images/general/scroll-icon.svg";
export { default as plusicon } from "./images/general/plus-icon.svg";
export { default as minusicon } from "./images/general/minus-icon.svg";
export { default as rightcirclewhite } from "./images/general/right-circle-arrow.svg";
export { default as mailicon } from "./images/general/mail-icon.svg";
export { default as phonecallicon } from "./images/general/phone-call-icon.svg";
export { default as phonecalliconRed } from "./images/general/phone-call-icon-red.svg";
export { default as callIconRed } from "./images/general/call-icon-red.svg";
export { default as searchicon } from "./images/general/search-icon.svg";
export { default as fbwhite } from "./images/general/fb-white.svg";
export { default as fbgrey } from "./images/general/fb-grey.svg";
export { default as instawhite } from "./images/general/insta-white.svg";
export { default as instagrey } from "./images/general/insta-grey.svg";
export { default as ytwhite } from "./images/general/yt-white.svg";
export { default as ytgrey } from "./images/general/yt-grey.svg";
export { default as linkedinwhite } from "./images/general/linkedin-white.svg";
export { default as linkedingrey } from "./images/general/linkedin-grey.svg";
export { default as footerlocation } from "./images/general/footer-location-icon.svg";
export { default as footermail } from "./images/general/footer-mail-icon.svg";
export { default as footercall } from "./images/general/footer-phone-call-icon.svg";
export { default as activearrow } from "./images/general/hamburger-arrow.svg";
export { default as downarrowred } from "./images/general/down-arrow2.svg";
export { default as coloredlist } from "./images/general/colored-list.svg";
export { default as coloredLogo } from "./images/general/colored-logo.svg";
export { default as locationIcon } from "./images/general/location-icon.png";
export { default as dummyVideo } from "./images/homepage/dummy-video.mp4";
export { default as playIcon } from "./images/homepage/play-circle.svg";
export { default as verticalLine } from "./images/general/vertical_line.svg";
export { default as horizontalLine } from "./images/general/horizontal_line.svg";
export { default as locationred } from "./images/contact/location-red.svg";

// Accomodation page images
export { default as accommodationBanner } from "./images/accomodation/banner-2.png";
export { default as mbAccommodationBanner } from "./images/accomodation/mb-banner-2.png";
export { default as restRoom } from "./images/accomodation/restrooms_1.jpg";

// why upgrad living images
export { default as whyUpgradLivingBanner } from "./images/whyUpgradLiving/Banner.jpg";
export { default as mbWhyUpgradLivingBanner } from "./images/whyUpgradLiving/mb-Banner.png";

// explore around you images
export { default as mbExploreBanner } from "./images/exploreAroundYou/mb_explore_banner.png";

// gallery images
export { default as galleryBanner } from "./images/gallery/gallery_banner.png";
export { default as mbGalleryBanner } from "./images/exploreAroundYou/mb_explore_banner.png";

// gallery images
export { default as LShape } from "./images/studentLife/L-line.svg";
export { default as VLine } from "./images/studentLife/verticle-line.svg";
export { default as HLine } from "./images/studentLife/horizontal-line.svg";

// dining images
export { default as diningBannerVideo } from "./video/Dining_1.mp4";
export { default as diningBannerVideoMb } from "./video/Mobile_dining_1.mp4";

// amenities images
export { default as amenitiesBannerVideo } from "./video/Amenities_1.mp4";
export { default as amenitiesBannerVideoMb } from "./video/Mobile_Amenities.mp4";

export let images = {
  // General Images
  leftArrow: {
    image: require("./images/general/upgrad-left.png"),
    alt: "upgrad left",
  },
  rightArrow: {
    image: require("./images/general/upgrad-right.png"),
    alt: "upgrad right",
  },
  appStore: {
    image: require("./images/general/app-store.png"),
    alt: "app store",
  },
  playStore: {
    image: require("./images/general/playstore.png"),
    alt: "play store",
  },
  callIcon: {
    image: require("./images/general/call-icon.png"),
    alt: "phone call",
  },
  dashedLine: {
    image: require("./images/general/header-active.png"),
    alt: "header active",
  },
  homeIcon: {
    image: require("./images/general/home-icon.png"),
    alt: "home icon",
  },
  scrollDown: {
    image: require("./images/general/scroll-down.png"),
    alt: "scroll down",
  },

  // Homepage Images
  banner01: {
    image: require("./images/homepage/banner-01.png"),
    alt: "banner 01",
  },
  why01: {
    image: require("./images/homepage/integrated-campus.png"),
    alt: "why 01",
  },
  mbWhy01: {
    image: require("./images/homepage/mb_intergrated_campus.png"),
    alt: "why 01",
  },
  why02: {
    image: require("./images/homepage/Safety-and-security-Monitoring-1.jpg"),
    alt: "why 02",
  },
  mbWhy02: {
    image: require("./images/homepage/mb-Safety-and-Security-Monitoring.png"),
    alt: "why 01",
  },
  why03: {
    image: require("./images/homepage/all-round-dev-2.png"),
    alt: "why 04",
  },
  mbWhy03: {
    image: require("./images/homepage/mb-all-round-dev-2.png"),
    alt: "why 01",
  },
  why04: {
    image: require("./images/homepage/food-service.jpg"),
    alt: "why 04",
  },
  why05: {
    image: require("./images/homepage/laundry-services.jpg"),
    alt: "why 05",
  },
  why06: {
    image: require("./images/homepage/Sense_of_community_2.png"),
    alt: "why 06",
  },
  mbWhy06: {
    image: require("./images/homepage/mb_Sense_of_community_2.png"),
    alt: "why 01",
  },
  why07: {
    image: require("./images/homepage/safety-and-security.jpg"),
    alt: "why 07",
  },
  accommodation01: {
    image: require("./images/homepage/4-share-room-2.png"),
    alt: "Upgrad Room",
  },
  accommodation02: {
    image: require("./images/homepage/3-share-room.jpg"),
    alt: "Upgrad Room",
  },
  accommodation03: {
    image: require("./images/homepage/2-share-room-2.png"),
    alt: "Upgrad Room",
  },
  accommodation01mb: {
    image: require("./images/homepage/mb-4-share-room-2.png"),
    alt: "Upgrad Room",
  },
  accommodation02mb: {
    image: require("./images/homepage/accommodation-01-mb-2.png"),
    alt: "Upgrad Room",
  },
  accommodation03mb: {
    image: require("./images/homepage/mb-2-share-room-2.png"),
    alt: "Upgrad Room",
  },
  amenities01: {
    image: require("./images/homepage/Community-Events-2.png"),
    alt: "amenities",
  },
  amenities02: {
    image: require("./images/homepage/Medical-room.png"),
    alt: "amenities",
  },
  amenities03: {
    image: require("./images/homepage/Accommodation-1.png"),
    alt: "amenities",
  },
  amenities04: {
    image: require("./images/homepage/Gym-&-Indoor-Games-1.png"),
    alt: "amenities",
  },
  amenities05: {
    image: require("./images/homepage/Exceptional-Food-Services.png"),
    alt: "amenities",
  },
  // Accommodation Icons here
  acIcon: {
    image: require("./images/homepage/accommodations-icons/air-conditioner-1.jpg"),
    alt: "air conditioner",
  },
  housekeepingIcon: {
    image: require("./images/homepage/accommodations-icons/bucket-1.jpg"),
    alt: "bucket",
  },
  accessIcon: {
    image: require("./images/homepage/accommodations-icons/access-control-1.jpg"),
    alt: "access control",
  },
  medicineIcon: {
    image: require("./images/homepage/accommodations-icons/medicine-1.jpg"),
    alt: "medicine",
  },
  // Student Life Images
  life01: {
    image: require("./images/homepage/life-01.jpg"),
    alt: "life 01",
  },

  // About Us Images
  aboutBanner: {
    image: require("./images/about/about-banner.png"),
    alt: "about banner",
  },
  mbAboutBanner: {
    image: require("./images/about/mb-about-banner.png"),
    alt: "about banner",
  },
  about01: {
    image: require("./images/about/Our_Vision.png"),
    alt: "about 01",
  },
  mbAbout01: {
    image: require("./images/about/mb_Our_Vision.png"),
    alt: "about 01",
  },
  about02: {
    image: require("./images/about/Our_Accommodation-1.png"),
    alt: "about 02",
  },
  mbAbout02: {
    image: require("./images/about/mb-Our_Accommodation-1.png"),
    alt: "about 01",
  },
  value01: {
    image: require("./images/about/Holistic_Learning.png"),
    alt: "value",
  },
  mbValue01: {
    image: require("./images/about/mb_Holistic_Learning.png"),
    alt: "value",
  },
  value02: {
    image: require("./images/about/all-round-dev-2.png"),
    alt: "value",
  },
  mbValue02: {
    image: require("./images/about/mb-all-round-dev-2.png"),
    alt: "value",
  },
  value03: {
    image: require("./images/about/Safety-and-security-Monitoring-1.jpg"),
    alt: "value",
  },
  mbValue03: {
    image: require("./images/about/mb-Safety-and-Security-Monitoring.png"),
    alt: "value",
  },
  value04: {
    image: require("./images/about/Sense_of_community.png"),
    alt: "value",
  },
  mbValue04: {
    image: require("./images/about/mb_Sense_of_community.png"),
    alt: "value",
  },

  // Amenenities Images
  amenityBanner: {
    image: require("./images/amenities/amenities-banner.png"),
    alt: "amenity banner",
  },
  mbAmenityBanner: {
    image: require("./images/amenities/mb-amenities-banner.png"),
    alt: "amenity banner",
  },
  amenity01: {
    image: require("./images/amenities/unwind-lounge.jpg"),
    alt: "amenity",
  },
  mbAmenity01: {
    image: require("./images/amenities/mb-unwind-lounge.png"),
    alt: "amenity",
  },
  amenity02: {
    image: require("./images/amenities/coffee-barista.jpg"),
    alt: "amenity",
  },
  mbAmenity02: {
    image: require("./images/amenities/mb-coffee-barista.png"),
    alt: "amenity",
  },
  amenity03: {
    image: require("./images/amenities/Courtyard.png"),
    alt: "amenity",
  },
  mbAmenity03: {
    image: require("./images/amenities/mb_Courtyard_1.png"),
    alt: "amenity",
  },
  amenity04: {
    image: require("./images/amenities/Collaborative_corridors.png"),
    alt: "amenity",
  },
  mbAmenity04: {
    image: require("./images/amenities/mb_Collaborative_corridors_1.png"),
    alt: "amenity",
  },
  amenity05: {
    image: require("./images/amenities/gymnasium.jpg"),
    alt: "amenity",
  },
  mbAmenity05: {
    image: require("./images/amenities/mb-gymnasium.png"),
    alt: "amenity",
  },
  amenity06: {
    image: require("./images/amenities/multisport-turf-1.png"),
    alt: "amenity",
  },
  mbAmenity06: {
    image: require("./images/amenities/mb-multisport-turf-1.png"),
    alt: "amenity",
  },
  amenity07: {
    image: require("./images/amenities/indoor-gaming.jpg"),
    alt: "amenity",
  },
  mbAmenity07: {
    image: require("./images/amenities/mb-indoor-gaming.png"),
    alt: "amenity",
  },
  amenity08: {
    image: require("./images/amenities/community-study-and-group-study-rooms-1.png"),
    alt: "amenity",
  },
  mbAmenity08: {
    image: require("./images/amenities/mb-community-study-and-group-study-rooms-1.png"),
    alt: "amenity",
  },
  amenity09: {
    image: require("./images/amenities/Coworking_spaces_2.png"),
    alt: "amenity",
  },
  mbAmenity09: {
    image: require("./images/amenities/mb_Coworking_spaces_2.png"),
    alt: "amenity",
  },
  amenity10: {
    image: require("./images/amenities/perimeter-security-and-daily.jpg"),
    alt: "amenity",
  },
  mbAmenity10: {
    image: require("./images/amenities/mb-perimeter-security-and-daily.png"),
    alt: "amenity",
  },
  amenity11: {
    image: require("./images/amenities/laundry-services.jpg"),
    alt: "amenity",
  },
  mbAmenity11: {
    image: require("./images/amenities/mb-laundry-services.png"),
    alt: "amenity",
  },
  amenity12: {
    image: require("./images/amenities/medical-team-and-care-network.jpg"),
    alt: "amenity",
  },
  mbAmenity12: {
    image: require("./images/amenities/mb-medical-team-and-care-network.png"),
    alt: "amenity",
  },
  amenity13: {
    image: require("./images/amenities/Medical_Team.png"),
    alt: "amenity",
  },
  mbAmenity13: {
    image: require("./images/amenities/mb_Medical_Team.png"),
    alt: "amenity",
  },
  amenity14: {
    image: require("./images/amenities/Breakout_Zones.png"),
    alt: "amenity",
  },
  mbAmenity14: {
    image: require("./images/amenities/mb_Breakout_Zones.png"),
    alt: "amenity",
  },
  amenity15: {
    image: require("./images/amenities/Community_Living.png"),
    alt: "amenity",
  },
  mbAmenity15: {
    image: require("./images/amenities/mb_Community_Living_1.png"),
    alt: "amenity",
  },
  amenity16: {
    image: require("./images/amenities/Events.png"),
    alt: "amenity",
  },
  mbAmenity16: {
    image: require("./images/amenities/mb_Events.png"),
    alt: "amenity",
  },
  amenity17: {
    image: require("./images/amenities/Pantry_Area.png"),
    alt: "amenity",
  },
  mbAmenity17: {
    image: require("./images/amenities/mb_Pantry_Area.png"),
    alt: "amenity",
  },
  amenity18: {
    image: require("./images/amenities/Vending_Machine.png"),
    alt: "amenity",
  },
  mbAmenity18: {
    image: require("./images/amenities/mb_Vending_Machine.png"),
    alt: "amenity",
  },

  // Accomodation page images
  sharing01: {
    image: require("./images/accomodation/4-share-room-2.png"),
    alt: "sharing",
  },
  mbSharing01: {
    image: require("./images/accomodation/mb-4-share-room-2.png"),
    alt: "sharing",
  },
  sharing02: {
    image: require("./images/accomodation/3-share-room-1.png"),
    alt: "sharing",
  },
  mbSharing02: {
    image: require("./images/accomodation/mb-3-share-room.png"),
    alt: "sharing",
  },
  sharing03: {
    image: require("./images/accomodation/2-share-room-2.png"),
    alt: "sharing",
  },
  mbSharing03: {
    image: require("./images/accomodation/mb-2-share-room-2.png"),
    alt: "sharing",
  },
  sharing04: {
    image: require("./images/accomodation/2-share-room-ensuite-washrooms.jpg"),
    alt: "sharing",
  },
  mbSharing04: {
    image: require("./images/accomodation/mb-2-share-room-ensuite-washrooms.png"),
    alt: "sharing",
  },
  restRoom00: {
    image: require("./images/accomodation/male-and-female.png"),
    alt: "Restroom",
  },
  restRoom01: {
    image: require("./images/accomodation/large_areas.png"),
    alt: "Restroom",
  },
  restRoom02: {
    image: require("./images/accomodation/shower.png"),
    alt: "Restroom",
  },
  restRoom03: {
    image: require("./images/accomodation/toilet-sign.png"),
    alt: "Restroom",
  },
  restRoom04: {
    image: require("./images/accomodation/washbasin.png"),
    alt: "Restroom",
  },
  restRoom05: {
    image: require("./images/accomodation/daily_cleanin.png"),
    alt: "Restroom",
  },
  restRoom06: {
    image: require("./images/accomodation/water_supply.png"),
    alt: "Restroom",
  },
  restRoom07: {
    image: require("./images/accomodation/suffiecient_shower.png"),
    alt: "Restroom",
  },
  restRoom08: {
    image: require("./images/accomodation/regular_maintenance.png"),
    alt: "Restroom",
  },
  restRoom09: {
    image: require("./images/accomodation/sanitary_basin.png"),
    alt: "Restroom",
  },
  acAcIcon: {
    image: require("./images/homepage/accommodations-icons/air-conditioner-1.jpg"),
    alt: "air conditioner",
  },
  acHousekeepingIcon: {
    image: require("./images/homepage/accommodations-icons/bucket-1.jpg"),
    alt: "bucket",
  },
  acAccessIcon: {
    image: require("./images/homepage/accommodations-icons/access-control-1.jpg"),
    alt: "access control",
  },
  acMedicineIcon: {
    image: require("./images/homepage/accommodations-icons/medicine-1.jpg"),
    alt: "medicine",
  },

  // DINING IMAGES
  diningBanner: {
    image: require("./images/dining/dining-banner-1.png"),
    alt: "dining banner",
  },
  mbDiningBanner: {
    image: require("./images/dining/mb-dining-banner.png"),
    alt: "dining banner",
  },
  dining01: {
    image: require("./images/dining/feels_like_home.png"),
    alt: "dining",
  },
  mbDining01: {
    image: require("./images/dining/mb_feels_like_home_1.png"),
    alt: "dining",
  },
  dining02: {
    image: require("./images/dining/delicious-and-nutritious.jpg"),
    alt: "dining",
  },
  dining03: {
    image: require("./images/dining/crafted.jpg"),
    alt: "dining",
  },
  mbDining03: {
    image: require("./images/dining/mb-crafted.png"),
    alt: "dining",
  },
  dining04: {
    image: require("./images/dining/your-feedback-our-inspiration.jpg"),
    alt: "dining",
  },
  mbDining04: {
    image: require("./images/dining/mb-your-feedback-our-inspiration.png"),
    alt: "dining",
  },
  diningCard01: {
    image: require("./images/dining/breakfast-2.jpg"),
    alt: "dining",
  },
  mbDiningCard01: {
    image: require("./images/dining/mb-breakfast-2.png"),
    alt: "dining",
  },
  diningCard02: {
    image: require("./images/dining/Lunch.png"),
    alt: "dining",
  },
  mbDiningCard02: {
    image: require("./images/dining/mb_lunch_1.png"),
    alt: "dining",
  },
  diningCard03: {
    image: require("./images/dining/High_Tea_&_Snacks_1.png"),
    alt: "dining",
  },
  mbDiningCard03: {
    image: require("./images/dining/mb_High_Tea_&_Snacks_2.png"),
    alt: "dining",
  },
  diningCard04: {
    image: require("./images/dining/Dinner.png"),
    alt: "dining",
  },
  mbDiningCard04: {
    image: require("./images/dining/mb_dinner_1.png"),
    alt: "dining",
  },

  // why upgrad living images
  whyUpgradLiving01: {
    image: require("./images/whyUpgradLiving/Facilities.jpg"),
    alt: "Facilities",
  },
  mbWhyUpgradLiving01: {
    image: require("./images/whyUpgradLiving/mb-Facilities.png"),
    alt: "Facilities",
  },
  whyUpgradLiving02: {
    image: require("./images/whyUpgradLiving/Location-1.png"),
    alt: "Location",
  },
  mbWhyUpgradLiving02: {
    image: require("./images/whyUpgradLiving/mb_Location_1.png"),
    alt: "Location",
  },
  whyUpgradLiving03: {
    image: require("./images/whyUpgradLiving/Services.jpg"),
    alt: "Services",
  },
  mbWhyUpgradLiving03: {
    image: require("./images/whyUpgradLiving/mb-Services.png"),
    alt: "Services",
  },
  whyUpgradLiving04: {
    image: require("./images/whyUpgradLiving/Amenities-2.png"),
    alt: "Amenities",
  },
  mbWhyUpgradLiving04: {
    image: require("./images/whyUpgradLiving/mb-Amenities-2.png"),
    alt: "Amenities",
  },

  // explore around you images
  exploreBanner: {
    image: require("./images/exploreAroundYou/eau-banner.png"),
    alt: "Explore Banner",
  },
  mbExploreBanner01: {
    image: require("./images/exploreAroundYou/mb-eau-banner.png"),
    alt: "Explore Banner",
  },
  explore00: {
    image: require("./images/exploreAroundYou/university.png"),
    alt: "Explore",
  },
  exploreMb00: {
    image: require("./images/exploreAroundYou/mb-university.png"),
    alt: "Explore",
  },
  explore01_01: {
    image: require("./images/exploreAroundYou/seamless-conectivity-1.png"),
    alt: "Explore",
  },
  explore01_02: {
    image: require("./images/exploreAroundYou/seamless-conectivity-2.png"),
    alt: "Explore",
  },
  exploreMb01_01: {
    image: require("./images/exploreAroundYou/mb_Airport_international_terminal_interior.png"),
    alt: "Explore",
  },
  exploreMb01_02: {
    image: require("./images/exploreAroundYou/mb_Metro_at_Gundavali_Station.png"),
    alt: "Explore",
  },
  explore02: {
    image: require("./images/exploreAroundYou/a-world-of-delicacies.png"),
    alt: "Explore",
  },
  exploreMb02: {
    image: require("./images/exploreAroundYou/mb_A_world_of_delicacies.png"),
    alt: "Explore",
  },
  explore03_01: {
    image: require("./images/exploreAroundYou/spend-fun-filled-1.png"),
    alt: "Explore",
  },
  explore03_02: {
    image: require("./images/exploreAroundYou/spend-fun-filled-2.png"),
    alt: "Explore",
  },
  exploreMb03_01: {
    image: require("./images/exploreAroundYou/mb-spend-fun-filled-1.png"),
    alt: "Explore",
  },
  exploreMb03_02: {
    image: require("./images/exploreAroundYou/mb-spend-fun-filled-2.png"),
    alt: "Explore",
  },
  explore04: {
    image: require("./images/exploreAroundYou/heritage-and-culture.png"),
    alt: "Explore",
  },
  exploreMb04: {
    image: require("./images/exploreAroundYou/mb-heritage-and-culture.png"),
    alt: "Explore",
  },
  explore05_01: {
    image: require("./images/exploreAroundYou/medical-emergency-1.png"),
    alt: "Explore",
  },
  explore05_02: {
    image: require("./images/exploreAroundYou/medical-emergency-2.png"),
    alt: "Explore",
  },
  exploreMb05_01: {
    image: require("./images/exploreAroundYou/mb-medical-emergency-1.png"),
    alt: "Explore",
  },
  exploreMb05_02: {
    image: require("./images/exploreAroundYou/mb-medical-emergency-2.png"),
    alt: "Explore",
  },
  explore06: {
    image: require("./images/exploreAroundYou/shop-till-you-drop.png"),
    alt: "Explore",
  },
  exploreMb06: {
    image: require("./images/exploreAroundYou/mb-shop-till-you-drop.png"),
    alt: "Explore",
  },
  explore07_01: {
    image: require("./images/exploreAroundYou/mountain-or-hiking-1.png"),
    alt: "Explore",
  },
  explore07_02: {
    image: require("./images/exploreAroundYou/mountain-or-hiking-2.png"),
    alt: "Explore",
  },
  exploreMb07_01: {
    image: require("./images/exploreAroundYou/mb-mountain-or-hiking-1.png"),
    alt: "Explore",
  },
  exploreMb07_02: {
    image: require("./images/exploreAroundYou/mb-mountain-or-hiking-2.png"),
    alt: "Explore",
  },

  // gallery images
  gallery01: {
    image: require("./images/gallery/gallery_1.png"),
    alt: "Explore",
  },
  mbGallery01: {
    image: require("./images/gallery/mb_gallery_1.png"),
    alt: "Explore",
  },
  gallery02: {
    image: require("./images/gallery/gallery_2.png"),
    alt: "Explore",
  },
  mbGallery02: {
    image: require("./images/gallery/mb_gallery_2.png"),
    alt: "Explore",
  },
  gallery03: {
    image: require("./images/gallery/gallery_3.png"),
    alt: "Explore",
  },
  mbGallery03: {
    image: require("./images/gallery/mb_gallery_3.png"),
    alt: "Explore",
  },
  gallery04: {
    image: require("./images/gallery/gallery_4_1.png"),
    alt: "Explore",
  },
  mbGallery04: {
    image: require("./images/gallery/mb_gallery_4_1.png"),
    alt: "Explore",
  },
  gallery05: {
    image: require("./images/gallery/gallery_5.png"),
    alt: "Explore",
  },
  mbGallery05: {
    image: require("./images/gallery/mb_gallery_5.png"),
    alt: "Explore",
  },
  gallery06: {
    image: require("./images/gallery/gallery_6.png"),
    alt: "Explore",
  },
  mbGallery06: {
    image: require("./images/gallery/mb_gallery_6.png"),
    alt: "Explore",
  },
  gallery07: {
    image: require("./images/gallery/gallery_7.png"),
    alt: "Explore",
  },
  mbGallery07: {
    image: require("./images/gallery/mb_gallery_7.png"),
    alt: "Explore",
  },
  gallery08: {
    image: require("./images/gallery/gallery_8.png"),
    alt: "Explore",
  },
  mbGallery08: {
    image: require("./images/gallery/mb_gallery_8.png"),
    alt: "Explore",
  },
  gallery09: {
    image: require("./images/gallery/gallery_9.png"),
    alt: "Explore",
  },
  mbGallery09: {
    image: require("./images/gallery/mb_gallery_9.png"),
    alt: "Explore",
  },
  gallery10: {
    image: require("./images/gallery/gallery_10.png"),
    alt: "Explore",
  },
  mbGallery10: {
    image: require("./images/gallery/mb_gallery_10.png"),
    alt: "Explore",
  },
  gallery11: {
    image: require("./images/gallery/gallery_11_1.png"),
    alt: "Explore",
  },
  mbGallery11: {
    image: require("./images/gallery/mb_gallery_11_2.png"),
    alt: "Explore",
  },
  gallery12: {
    image: require("./images/gallery/gallery_12.png"),
    alt: "Explore",
  },
  mbGallery12: {
    image: require("./images/gallery/mb_gallery_12.png"),
    alt: "Explore",
  },
  gallery13: {
    image: require("./images/gallery/gallery_13.png"),
    alt: "Explore",
  },
  mbGallery13: {
    image: require("./images/gallery/mb_gallery_13.png"),
    alt: "Explore",
  },
  gallery14: {
    image: require("./images/gallery/gallery_14.png"),
    alt: "Explore",
  },
  mbGallery14: {
    image: require("./images/gallery/mb_gallery_14.png"),
    alt: "Explore",
  },
  gallery15: {
    image: require("./images/gallery/gallery_15.png"),
    alt: "Explore",
  },
  mbGallery15: {
    image: require("./images/gallery/mb_gallery_15_2.png"),
    alt: "Explore",
  },
  gallery16: {
    image: require("./images/gallery/gallery_16.png"),
    alt: "Explore",
  },
  mbGallery16: {
    image: require("./images/gallery/mb_gallery_16.png"),
    alt: "Explore",
  },

  // STUDENT LIFE IMAGES
  studentBanner: {
    image: require("./images/studentLife/banner.jpg"),
    alt: "Banner",
  },
  mbStudentBanner: {
    image: require("./images/studentLife/mb-banner.png"),
    alt: "Banner",
  },
  morningWorkouts: {
    image: require("./images/studentLife/morning-workouts.jpg"),
    alt: "morning workouts",
  },
  mbMorningWorkouts: {
    image: require("./images/studentLife/mb-morning-workouts.png"),
    alt: "morning workouts",
  },
  classroomMinute: {
    image: require("./images/studentLife/Classroom-in-a-min.png"),
    alt: "classroom in a minute",
  },
  mbClassroomMinute: {
    image: require("./images/studentLife/mb_Classroom-in-a-min-1.png"),
    alt: "classroom in a minute",
  },
  relaxingLunch: {
    image: require("./images/studentLife/indoor-games-1.png"),
    alt: "relaxing afternoon and lunch post classes",
  },
  mbRelaxingLunch: {
    image: require("./images/studentLife/mb-indoor-games-1.png"),
    alt: "relaxing afternoon and lunch post classes",
  },
  breakfastDone: {
    image: require("./images/studentLife/breakfast-done-the-mumbai-way-1.jpg"),
    alt: "breakfast done the mumbai way",
  },
  mbBreakfastDone: {
    image: require("./images/studentLife/mb-breakfast-done-the-mumbai-way-1.png"),
    alt: "breakfast done the mumbai way",
  },
  studyTime: {
    image: require("./images/studentLife/study-time.jpg"),
    alt: "study time",
  },
  mbStudyTime: {
    image: require("./images/studentLife/mb-study-time.png"),
    alt: "study time",
  },
  matchTurf: {
    image: require("./images/studentLife/match-of-cricket-and-turf.jpg"),
    alt: "Match of cicket and turf",
  },
  mbMatchTurf: {
    image: require("./images/studentLife/mb-match-of-cricket-and-turf.png"),
    alt: "Match of cicket and turf",
  },
  EndingDay: {
    image: require("./images/studentLife/ending-a-power-packed-day.jpg"),
    alt: "Ending a power-packed day",
  },
  mbEndingDay: {
    image: require("./images/studentLife/mb-ending-a-power-packed-day.png"),
    alt: "Ending a power-packed day",
  },
  SplendidDinner: {
    image: require("./images/studentLife/splendid-dinner-and-relaxation-1.jpg"),
    alt: "Splendid dinner and relaxation",
  },
  mbSplendidDinner: {
    image: require("./images/studentLife/mb-splendid-dinner-and-relaxation.png"),
    alt: "Splendid dinner and relaxation",
  },
  weekendAdventures: {
    image: require("./images/studentLife/weekend-adventures-in-mumbai.jpg"),
    alt: "Weekend adventures in Mumbai",
  },
  mbWeekendAdventures: {
    image: require("./images/studentLife/mb-weekend-adventures-in-mumbai.png"),
    alt: "Weekend adventures in Mumbai",
  },
  movieNight: {
    image: require("./images/studentLife/Movie-Night-1.png"),
    alt: "Weekend adventures in Mumbai",
  },
  mbMovieNight: {
    image: require("./images/studentLife/mb_Movie-Night.png"),
    alt: "Weekend adventures in Mumbai",
  },
  festivalCelebration: {
    image: require("./images/studentLife/Festival-Celebrations.png"),
    alt: "Weekend adventures in Mumbai",
  },
  mbFestivalCelebration: {
    image: require("./images/studentLife/mb_Festival-Celebrations.png"),
    alt: "Weekend adventures in Mumbai",
  },

  // T&C IMAGES
  termsBanner: {
    image: require("./images/general/t&c-banner.jpg"),
    alt: "terms and condition banner",
  },
};
