import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Routing from "./Routing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MetaDecorator from "./components/MetaDecorator/MetaDecorator";

// Import AOS styles
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      // once: true,
    });
  }, []);
  return (
    <>
       <MetaDecorator
        metaTitle="A Home Away from Home"
        metaDesc="A Home away from Home"
        canonicalLink={window.location}
      />
      <ToastContainer />

      <BrowserRouter>
        {/* <ScrollToTop /> */}
        <Routes>
          <Route path="*" element={<Routing />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
