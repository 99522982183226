import React, { useEffect, useState } from "react";
import "./contact.scss";
import { images, locationred, mailicon, phonecalliconRed } from "../../source";
import Joi from "joi";
import { toast } from "react-toastify";
import axios from "axios";
import config from "../../config/config";
import { useSearchParams, useNavigate } from "react-router-dom";
import { contactURL } from "../../helpers/paths";
import CommonBanner from "../../components/CommonBanner/CommonBanner";

const Contact = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  let base_url = config.crm_api_url + "/erp/api/enquiry";

  const [searchParams, setSearchParams] = useSearchParams();

  let utm = {
    utm_source: searchParams.get("utm_source") || "NA",
    utm_medium: searchParams.get("utm_medium") || "NA",
    utm_campaign: searchParams.get("utm_campaign") || "NA",
    utm_content: searchParams.get("utm_content") || "NA",
    ad_name: searchParams.get("ad_name") || "NA",
    placement: searchParams.get("placement") || "NA",
  };

  const schema = Joi.object({
    name: Joi.string().trim().required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),

    phone: Joi.string()
      .length(10)
      .pattern(/^[0-9]+$/)
      .messages({ "string.pattern.base": `Mobile number must have 10 digits.` })
      .required()
      .label("Mobile Number"),
    message: Joi.string().trim().required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();

    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(`${base_url}`, { ...values, ...utm });

      const { data } = response;

      if (data?.status === 1) {
        toast.success("Thank you!");
      } else {
        toast.error(data?.msg);
      }

      navigate(`${contactURL}?e=submitted`, { replace: true });
    } catch (error) {
      toast.error("Internal Server Error");

      console.log("error", error.response);
    }
    setValues({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <CommonBanner
        text1="Contact Us"
        disable
        title="CONTACT <br />US"
        image={images.aboutBanner.image}
      />
      <section className="contact_sec1" data-aos="fade-in">
        <div className="my_container">
          <div className="row boxes">
            <div className="col-md-4 col-lg-4 box-padding">
              <div className="contact_box">
                <img
                  src={locationred}
                  alt="location icon"
                  className="icon_img"
                  loading="lazy"
                />
                <div className="heading">upGrad Education Private Limited</div>
                <p className="description">
                  <a
                    href="https://maps.app.goo.gl/QqH6bLjAoCSRA5Eu6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* Equinox Business Park, Ambedkar Nagar, Kurla West. */}
                    upGrad Living, Sunteck BKC 51, LBS Marg, Kurla West, Mumbai-
                    400070
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 box-padding">
              <div className="contact_box">
                <img
                  src={mailicon}
                  alt="location icon"
                  className="icon_img"
                  loading="lazy"
                />
                <div className="heading">Email Us</div>
                <div>
                  <a
                    className="description mail_link"
                    // href="mailto:living@atlasuniversity.edu.in"
                    href="mailto:living@upgrad.com"
                  >
                    living@upgrad.com
                  </a>
                </div>
                {/* <a
                  className="description"
                  href="mailto:info@upgradliving.com"
                >
                  info@upgradliving.com
                </a> */}
              </div>
            </div>
            <div className="col-md-4 col-lg-4 box-padding">
              <div className="contact_box contact_box3">
                <img
                  src={phonecalliconRed}
                  alt="location icon"
                  className="icon_img"
                  loading="lazy"
                />
                <div className="heading">Contact Us</div>
                {/* <a className="description" href="tel:+91 80975 20623">
                Priyatama Gharat: <br />
                +91 80975 20623
                </a> */}
                {/* <a className="description" href="tel:+91 99208 87730">
                  Bhargavi Deshpande: <br />
                  +91 99208 87730
                </a> */}
                {/* <br /> */}
                {/* <a className="description" href="tel:+91 99307 67612">
                  Kunal Ramchandani: <br />
                  +91 99307 67612
                </a> */}
                <br />
                <div>
                  <a className="description" href="tel:+91 90041 06218">
                    {/* <span className="description"> */}
                    <span className="numb_title">
                      For Newly Enrolled Students in Batch 2024:
                    </span>
                    Abhijeet Patil: <br />
                    <span className="contact_no">+91 90041 06218</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact_sec2" data-aos="fade-up">
        <div className="my_container">
          <div className="row">
            <div className="col-md-7 col-lg-7">
              <iframe
                title="google map"
                className="map_frame"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.8482269633414!2d72.8761873!3d19.0704095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9e311cbe067%3A0x1e01c89871a24f04!2sSunteck%20BKC51!5e0!3m2!1sen!2sin!4v1679549610188!5m2!1sen!2sin"
                // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.836832625515!2d72.8759417!3d19.0709103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c92bc9049be3%3A0x6e303efe77de54ed!2sATLAS%20SkillTech%20University!5e0!3m2!1sen!2sin!4v1677262399590!5m2!1sen!2sin"
                height={657}
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="col-md-5 col-lg-5">
              <form className="contact_form" onSubmit={handleSubmit}>
                <h1 className="heading">Get In Touch</h1>
                <div className="form_field_wrapper">
                  <input
                    type="text"
                    className="form_input"
                    placeholder="Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  <p className="error_message">{formError.name}</p>
                </div>
                <div className="form_field_wrapper">
                  <input
                    type="tel"
                    className="form_input"
                    placeholder="Mobile No"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                  />
                  <p className="error_message">{formError.phone}</p>
                </div>
                <div className="form_field_wrapper">
                  <input
                    type="text"
                    className="form_input"
                    placeholder="Email ID"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <p className="error_message">{formError.email}</p>
                </div>

                <div className="form_field_wrapper">
                  <input
                    type="text"
                    className="form_input"
                    placeholder="Message"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                  />
                  <p className="error_message">{formError.message}</p>
                </div>
                <div className="cta_wrapper">
                  <button className="common_cta" type="submit">
                    {loading ? "Loading.." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
