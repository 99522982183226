import React, { useEffect } from "react";
import { homeURL } from "../../helpers/paths";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import { images } from "../../source";

const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <CommonBanner
        text1="Page not found"
        disable
        title="PAGE NOT <br /> FOUND"
        image={images.aboutBanner.image}
      />
      <section className="notfound_sec1">
        <div className="my_container">
          <div
            className="flex_box text-center"
            style={{ paddingTop: 50, paddingBottom: 50 }}
          >
            <h1 className="title" style={{ fontSize: "10em" }}>
              404
            </h1>
            <h5
              className="subtitle"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              {/* PAGE NOT FOUND */}
            </h5>
            <h6 className="desc" style={{ paddingBottom: 20 }}>
              Sorry, the page you're looking for doesn't exist.
            </h6>
            <h6 className="cta_wrapper">
              <a href={homeURL}>Go to Homepage</a>
            </h6>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
