import React, { useEffect, useRef, useState } from "react";
import "./dining.scss";
import { diningBannerData, diningValuesData } from "../../data/data";
import { diningBannerVideo, diningBannerVideoMb, images } from "../../source";
import { bookingURL } from "../../helpers/paths";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ImageContentBox from "../../components/ImageContentBox/ImageContentBox";
import { useWindowSize } from "react-use";

const Dining = () => {
  const [showVideo, setShowVideo] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const section2Ref = useRef(null);
  const { width } = useWindowSize();

  const videoHandle = (i) => {
    setShowVideo(i);
    setIsMuted(true);
  };

  const soundHandle = () => {
    setIsMuted((prevIsMuted) => !prevIsMuted);
  };

  const scrollToRef = (ref, offset) => {
    if (ref && ref.current) {
      const top = ref.current.offsetTop - offset;
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    }
  };

  const diningBanner = diningBannerData.map((data, i) => (
    <React.Fragment key={i}>
      <div className="banner_wrap">
        <div className="video_banner">
          {/* {!showVideo && ( */}
          <div className="thumbnail_wrapper">
            <img
              width={1920}
              height={1080}
              src={width > 767 ? data.banImg : data.mbBanImg}
              alt={data.alt}
              className="banner_img"
            />
            {/* <img
                  width={95}
                  height={95}
                  src={playIcon}
                  alt="play icon"
                  className="play_icon"
                  onClick={() => videoHandle(data.id)}
                /> */}
          </div>
          {/* )} */}
          {/* {showVideo && (
                <video
                  width={1920}
                  height={1080}
                  autoPlay
                  loop
                  muted={isMuted ? 0 : 1}
                  playsinline
                  className="w-full h-full object-cover upgrad_video"
                  onClick={soundHandle}
                >
                  <source src={data.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )} */}
        </div>
      </div>
    </React.Fragment>
  ));

  const valueList = diningValuesData.map((data, i) => (
    <React.Fragment key={i}>
      <div className="box" data-aos="fade-up" data-aos-delay={i * 100}>
        <div className="image_title_wrap">
          <img
            width={480}
            height={587}
            src={width > 767 ? data.img : data.mbImg}
            alt={images.value01.alt}
            className="back_img"
            loading="lazy"
          />
          <h3 className="box_title">{data.title}</h3>
        </div>
        <div className="box_content">
          <p className="box_description">{data.description}</p>
        </div>
      </div>
    </React.Fragment>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="dining_sec1" data-aos="fade">
        <div className="my_container">
          <Breadcrumb text1="Dining" disable />
          <img
            width={42}
            height={33}
            src={images.scrollDown.image}
            alt={images.scrollDown.alt}
            className="scroll_down"
            loading="lazy"
            onClick={() => scrollToRef(section2Ref, 100)}
          />
        </div>
        <div className="banner_wrap">
          <div className="video_banner bg-white">
            <video
              width={1920}
              height={1080}
              autoPlay
              loop
              muted={isMuted ? 0 : 1}
              playsInline
              className="w-full h-full object-cover upgrad_video m-0 p-0 bg-white test"
              // onClick={soundHandle}
            >
              <source
                src={width > 600 ? diningBannerVideo : diningBannerVideoMb}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        {/* <div className="my_container full_width_container zero_padding">
          {diningBanner}
        </div> */}
      </section>

      <div className="my_container">
        <section className="dining_sec2" ref={section2Ref}>
          <div className="dining_hd_sec">
            <h2 className="section_title vert_horizon_line" data-aos="fade-up">
              Dining at upGrad Living: <br />
              <span className="bold_text">A Taste of Home</span>
            </h2>
            <p
              className="section_description"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              At upGrad Living, we understand that food is more than just
              nourishment; it’s a way to bring people together, create memories,
              and offer a taste of home. That’s why we’ve dedicated ourselves to
              providing a dining experience that does just that. Our community
              dining hall is not just a place to eat; it’s a space where
              students can unwind, relax, and enjoy the company of friends and
              peers over a delicious nutritious meal and special food as per
              festivals!
            </p>
          </div>
        </section>
      </div>

      <section className="dining_sec3">
        <div className="my_container full_width_container zero_padding">
          <ImageContentBox
            img={images.dining01.image}
            mbImg={images.mbDining01.image}
            title="A Menu That"
            boldTitle="<br />Feels Like Home"
            description="Our commitment to your well-being is reflected in the meals we serve. Each day, we offer four fresh, nutritious, and hot meals designed to remind you of home. Our menu is a thoughtful blend of flavors and traditions, crafted to cater to a wide range of dietary needs and preferences. Whether you follow a Jain, Vegetarian, or Non-Vegetarian diet, you’ll find options that not only satisfy your hunger but also delight your taste buds."
            ctaUrl={bookingURL}
            ctaText="Book Now"
            // newTab
            ctaClass="book_now_cta"
          />
        </div>
      </section>

      <section className="dining_sec4">
        <div className="my_container full_width_container zero_padding">
          <div className="row-1">{valueList}</div>
        </div>
      </section>
      <div className="my_container full_width_container zero_padding">
        <section className="dining_sec5">
          <ImageContentBox
            img={images.dining03.image}
            mbImg={images.mbDining03.image}
            title="Crafted With"
            boldTitle="Care"
            description="We believe that variety is the spice of life, which is why our menu is periodically revised. Our culinary team takes inspiration from traditional and contemporary recipes to keep our offerings exciting and diverse. But what truly sets our dining experience apart is our commitment to incorporating student feedback and preferences. We understand that our community is diverse, and we strive to reflect that diversity in the meals we serve."
            ctaUrl={bookingURL}
            ctaText="Book Now"
            layout2
            // newTab
            ctaClass="book_now_cta"
          />
        </section>
        <section className="dining_sec6">
          <ImageContentBox
            img={images.dining04.image}
            mbImg={images.mbDining04.image}
            title="Your Feedback,"
            boldTitle="<br />Our Inspiration"
            description="Your dining experience at upGrad Living is a collaborative effort. We actively seek out and welcome student feedback to ensure our dining hall remains a place where everyone feels heard and valued. Whether it’s a dish you’re missing from home or a new cuisine you’d like to explore, we’re here to listen and adapt. After all, our dining hall is more than just a place to eat—it’s a place where you can feel at home."
            ctaUrl={bookingURL}
            ctaText="Book Now"
            // newTab
            ctaClass="book_now_cta"
          />
        </section>
      </div>
    </>
  );
};

export default Dining;
