import React, { useEffect } from "react";
import "./studentlife.scss";
import { studentLifeBannerData, studentLifeData } from "../../data/data";
import { LShape } from "../../source";
import { useWindowSize } from "react-use";
import CommonBanner from "../../components/CommonBanner/CommonBanner";

const StudentLife = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="sl_sec1" data-aos="fade">
        <CommonBanner
          text1="Student Life"
          disable
          title="STUDENT <br/> LIFE"
          image={
            width > 767
              ? studentLifeBannerData[0].banImg
              : studentLifeBannerData[0].mbBanImg
          }
        />
      </section>

      <div className="my_container">
        <section className="sl_sec2">
          <div className="sl_hd_sec">
            <h2 className="section_title vert_horizon_line" data-aos="fade-up">
              Student Life at ATLAS: <br />
              <span className="bold_text">An Integrated Campus Experience</span>
            </h2>
            <p
              className="section_description"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Welcome to the vibrant and dynamic world of ATLAS, where the
              essence of student life is beautifully woven into the fabric of
              our integrated campus. Here, every corner is designed to enhance
              your academic journey with a blend of wellness, community, and the
              spirit of Mumbai. Let’s embark on a typical day in the life of an
              ATLAS student.
            </p>
          </div>
        </section>
      </div>

      <section className="sl_sec3">
        <div className="my_container custom_space">
          <div className="box_layout box_layout01">
            <div className="column column1">
              <div className="container-1">
                <div className="box box-01">
                  <img
                    width={316}
                    height={73}
                    src={LShape}
                    alt="Connect"
                    className="connecting_border"
                    loading="lazy"
                  />
                  <div className="image_title_wrap">
                    <img
                      width={559}
                      height={497}
                      src={
                        width > 767
                          ? studentLifeData[0].img
                          : studentLifeData[0].mbImg
                      }
                      alt="student life"
                      className="back_img"
                      loading="lazy"
                    />
                    <h3 className="box_title">{studentLifeData[0].title}</h3>
                  </div>
                  <div className="box_content">
                    <p className="box_description">{studentLifeData[0].desc}</p>
                  </div>
                </div>
                <div className="box box-02">
                  <img
                    width={316}
                    height={73}
                    src={LShape}
                    alt="Connect"
                    className="connecting_border"
                    loading="lazy"
                  />
                  <div className="image_title_wrap">
                    <img
                      width={559}
                      height={497}
                      src={
                        width > 767
                          ? studentLifeData[1].img
                          : studentLifeData[1].mbImg
                      }
                      alt="student life"
                      className="back_img"
                      loading="lazy"
                    />
                    <h3 className="box_title">{studentLifeData[1].title}</h3>
                  </div>
                  <div className="box_content">
                    <p className="box_description">{studentLifeData[1].desc}</p>
                  </div>
                </div>
              </div>
              <div className="container-2">
                <div className="box box-04">
                  <img
                    width={316}
                    height={73}
                    src={LShape}
                    alt="Connect"
                    className="connecting_border"
                    loading="lazy"
                  />
                  <div className="image_title_wrap">
                    <img
                      width={1160}
                      height={497}
                      src={
                        width > 767
                          ? studentLifeData[3].img
                          : studentLifeData[3].mbImg
                      }
                      alt="student life"
                      className="back_img"
                      loading="lazy"
                    />
                    <h3 className="box_title">{studentLifeData[3].title}</h3>
                  </div>
                  <div className="box_content">
                    <p className="box_description">{studentLifeData[3].desc}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column2">
              <div className="box box-03">
                <img
                  width={316}
                  height={73}
                  src={LShape}
                  alt="Connect"
                  className="connecting_border"
                  loading="lazy"
                />
                <div className="image_title_wrap">
                  <img
                    width={612}
                    height={878}
                    src={
                      width > 767
                        ? studentLifeData[2].img
                        : studentLifeData[2].mbImg
                    }
                    alt="student life"
                    className="back_img"
                    loading="lazy"
                  />
                  <h3 className="box_title">{studentLifeData[2].title}</h3>
                </div>
                <div className="box_content">
                  <p className="box_description">{studentLifeData[2].desc}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="box_layout box_layout02">
            <div className="column column1">
              <div className="box box-05">
                <img
                  width={316}
                  height={73}
                  src={LShape}
                  alt="Connect"
                  className="connecting_border"
                  loading="lazy"
                />
                <div className="image_title_wrap">
                  <img
                    width={612}
                    height={498}
                    src={
                      width > 767
                        ? studentLifeData[4].img
                        : studentLifeData[4].mbImg
                    }
                    alt="student life"
                    className="back_img"
                    loading="lazy"
                  />
                  <h3 className="box_title">{studentLifeData[4].title}</h3>
                </div>
                <div className="box_content">
                  <p className="box_description">{studentLifeData[4].desc}</p>
                </div>
              </div>
            </div>
            <div className="column column2">
              <div className="box box-06">
                <img
                  width={316}
                  height={73}
                  src={LShape}
                  alt="Connect"
                  className="connecting_border"
                  loading="lazy"
                />
                <div className="image_title_wrap">
                  <img
                    width={1160}
                    height={498}
                    src={
                      width > 767
                        ? studentLifeData[5].img
                        : studentLifeData[5].mbImg
                    }
                    alt="student life"
                    className="back_img"
                    loading="lazy"
                  />
                  <h3 className="box_title">{studentLifeData[5].title}</h3>
                </div>
                <div className="box_content">
                  <p className="box_description">{studentLifeData[5].desc}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="box_layout box_layout03">
            <div className="column column1">
              <div className="container-1">
                <div className="box box-07">
                  <img
                    width={316}
                    height={73}
                    src={LShape}
                    alt="Connect"
                    className="connecting_border"
                    loading="lazy"
                  />
                  <div className="image_title_wrap">
                    <img
                      width={1160}
                      height={497}
                      src={
                        width > 767
                          ? studentLifeData[6].img
                          : studentLifeData[6].mbImg
                      }
                      alt="student life"
                      className="back_img"
                      loading="lazy"
                    />
                    <h3 className="box_title">{studentLifeData[6].title}</h3>
                  </div>
                  <div className="box_content">
                    <p className="box_description">{studentLifeData[6].desc}</p>
                  </div>
                </div>
              </div>
              <div className="container-2">
                <div className="box box-09">
                  <img
                    width={316}
                    height={73}
                    src={LShape}
                    alt="Connect"
                    className="connecting_border"
                    loading="lazy"
                  />
                  <div className="image_title_wrap">
                    <img
                      width={1160}
                      height={507}
                      src={
                        width > 767
                          ? studentLifeData[8].img
                          : studentLifeData[8].mbImg
                      }
                      alt="student life"
                      className="back_img"
                      loading="lazy"
                    />
                    <h3 className="box_title">{studentLifeData[8].title}</h3>
                  </div>
                  <div className="box_content">
                    <p className="box_description">{studentLifeData[8].desc}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column column2">
              <div className="box box-08">
                <img
                  width={316}
                  height={73}
                  src={LShape}
                  alt="Connect"
                  className="connecting_border"
                  loading="lazy"
                />
                <div className="image_title_wrap">
                  <img
                    width={612}
                    height={878}
                    src={
                      width > 767
                        ? studentLifeData[7].img
                        : studentLifeData[7].mbImg
                    }
                    alt="student life"
                    className="back_img"
                    loading="lazy"
                  />
                  <h3 className="box_title">{studentLifeData[7].title}</h3>
                </div>
                <div className="box_content">
                  <p className="box_description">{studentLifeData[7].desc}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="box_layout box_layout02">
            <div className="column column1">
              <div className="box box-05">
                <img
                  width={316}
                  height={73}
                  src={LShape}
                  alt="Connect"
                  className="connecting_border"
                  loading="lazy"
                />
                <div className="image_title_wrap">
                  <img
                    width={612}
                    height={498}
                    src={
                      width > 767
                        ? studentLifeData[9].img
                        : studentLifeData[9].mbImg
                    }
                    alt="student life"
                    className="back_img"
                    loading="lazy"
                  />
                  <h3 className="box_title">{studentLifeData[9].title}</h3>
                </div>
                <div className="box_content">
                  <p className="box_description">{studentLifeData[9].desc}</p>
                </div>
              </div>
            </div>
            <div className="column column2">
              <div className="box box-06">
                <img
                  width={316}
                  height={73}
                  src={LShape}
                  alt="Connect"
                  className="connecting_border"
                  loading="lazy"
                />
                <div className="image_title_wrap">
                  <img
                    width={1160}
                    height={498}
                    src={
                      width > 767
                        ? studentLifeData[10].img
                        : studentLifeData[10].mbImg
                    }
                    alt="student life"
                    className="back_img"
                    loading="lazy"
                  />
                  <h3 className="box_title">{studentLifeData[10].title}</h3>
                </div>
                <div className="box_content">
                  <p className="box_description">{studentLifeData[10].desc}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StudentLife;
