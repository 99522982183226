export const homeURL = "/";
export const aboutURL = "/about-us";
export const whyUpgrandLivingURL = "/why-upgrad-living";
export const accommodationURL = "/accommodation";
export const diningURL = "/dining";
export const exploreAroundYouURL = "/explore-around-you";
export const galleryURL = "/gallery";
export const amenitiesURL = "/amenities";
export const feesURL = "/fees";
export const studentLifeURL = "/student-life";
export const privacypolicyURL = "/privacy-policy";
export const contactURL = "/contact-us";
export const termsandconditionURL = "/terms-and-conditions";
export const thankyouURL = "/thank-you";
export const bookingURL = "https://booking.upgradliving.com/";
export const refundURL = "https://booking.upgradliving.com/refundpolicy/";
