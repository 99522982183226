import React from "react";
import "./imagecontentbox.scss";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";

const ImageContentBox = ({
  img,
  mbImg,
  title,
  boldTitle,
  description,
  ctaUrl,
  ctaUrl1,
  ctaText,
  ctaText1,
  layout2,
  newTab,
  newTab1,
  ctaClass,
  ctaClass1,
}) => {
  const { width } = useWindowSize();
  return (
    <div className="box_layout_01">
      <div className="row">
        <div
          className={`col-lg-6 zero_padding ${layout2 && "order-lg-2"}`}
          data-aos="fade-up"
        >
          <img
            width={960}
            height={696}
            src={width > 600 ? img : mbImg}
            alt="box"
            className="box_img"
            loading="lazy"
          />
        </div>
        <div className={`col-lg-6 zero_padding ${layout2 && "order-lg-1"}`}>
          <div className="box_text">
            {title && (
              <h3 className="box_title" data-aos="fade-up" data-aos-delay="100">
                {title}{" "}
                <span
                  className="bold_text"
                  dangerouslySetInnerHTML={{ __html: boldTitle }}
                />
              </h3>
            )}
            {description && (
              <p
                className="section_description box_description"
                data-aos="fade-up"
                data-aos-delay="200"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            {ctaUrl && (
              <div
                className="cta_wrapper"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Link to={ctaUrl} className={`common_cta ${ctaClass ? ctaClass : "" }`} target={newTab && "_blank"} >
                  {ctaText}
                </Link>
                {ctaUrl1 && (
                <Link to={ctaUrl1} className={`common_cta ${ctaClass1 ? ctaClass1 : "" }`} target={newTab1 && "_blank"} >
                  {ctaText1}
                </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageContentBox;
