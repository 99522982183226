import React from "react";
import "./breadcrumb.scss";
import { Link } from "react-router-dom";
import { homeURL } from "../../helpers/paths";
import { images } from "../../source";

const Breadcrumb = ({ url1, text1, text2, disable, disable2 }) => {
  return (
    <div className="breadcrumb_wrapper">
      <Link to={homeURL} className="blink_1">
        <img
          width={20}
          height={18}
          src={images.homeIcon.image}
          alt={images.homeIcon.alt}
          className="home_icon"
          loading="lazy"
        />
      </Link>
      {text1 && (
        <Link to={`/${url1}`} className={`blink_1 ${disable && "noclick"}`}>
          &nbsp;{" > "}&nbsp;
          {text1}
        </Link>
      )}
      {text2 && (
        <div className={`blink_1 ${disable2 && "noclick"}`}>
          {" > "}
          {text2}
        </div>
      )}
    </div>
  );
};

export default Breadcrumb;
