import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./header.scss";
import HeaderNavItem from "./HeaderNavItem";
import { coloredLogo } from "../../source";
import {
  aboutURL,
  accommodationURL,
  amenitiesURL,
  bookingURL,
  contactURL,
  diningURL,
  exploreAroundYouURL,
  galleryURL,
  homeURL,
  studentLifeURL,
  whyUpgrandLivingURL,
} from "../../helpers/paths";
import { useWindowSize } from "react-use";
const body = document.querySelector("body");

const navLinksArr = [
  {
    id: 1,
    type: "img",
    whiteLogo: coloredLogo,
    colorLogo: coloredLogo,
    mainLink: homeURL,
    class: "logo_img",
  },
  {
    id: 2,
    type: "text",
    mainTitle: "Call Now",
    mainLink: "tel:+919004106218",
    class: "common_cta book_now_cta",
    // linkType: "external",
  },
  {
    id: 3,
    type: "text",
    mainTitle: "Book Now",
    mainLink: bookingURL,
    class: "common_cta book_now_cta",
    // linkType: "external",
  },
  {
    id: 4,
    type: "text",
    mainTitle: "Virtual Tour",
    mainLink: "https://youtu.be/pe8Y1EIgSGY",
    class: "common_cta virtual_cta",
    linkType: "external",
  },
];
const mobNavLinksArr = [
  {
    id: 1,
    type: "text",
    mainTitle: "About",
    mainLink: aboutURL,
  },
  {
    id: 2,
    type: "text",
    mainTitle: "Why upGrad Living",
    mainLink: whyUpgrandLivingURL,
  },
  {
    id: 3,
    type: "text",
    mainTitle: "Booking Process",
    mainLink: bookingURL,
    // external: true,
  },
  {
    id: 4,
    type: "text",
    mainTitle: "Accommodation",
    mainLink: accommodationURL,
  },
  {
    id: 5,
    type: "text",
    mainTitle: "Dining",
    mainLink: diningURL,
  },
  {
    id: 6,
    type: "text",
    mainTitle: "Amenities",
    mainLink: amenitiesURL,
  },
  {
    id: 7,
    type: "text",
    mainTitle: "Explore Around You",
    mainLink: exploreAroundYouURL,
  },
  {
    id: 8,
    type: "text",
    mainTitle: "Student Life",
    mainLink: studentLifeURL,
  },
  // {
  //   id: 9,
  //   type: "text",
  //   mainTitle: "Rules & Regulations",
  //   mainLink: "",
  // },
  {
    id: 10,
    type: "text",
    mainTitle: "Gallery",
    mainLink: galleryURL,
  },
  // {
  //   id: 11,
  //   type: "text",
  //   mainTitle: "App",
  //   mainLink: "",
  // },
  {
    id: 11,
    type: "text",
    mainTitle: "Contact Us",
    mainLink: contactURL,
  },
];
const HeaderNew = () => {
  const [navlinkIndex, setNavlinkIndex] = useState(null);
  const { pathname: locationPathname } = useLocation();
  const [navActiveIndex, setNavActiveIndex] = useState(null);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isBg, setIsbg] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    body.style.overflow = "auto";
    setIsMenuActive(false);
  }, [locationPathname]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  });

  const handleScroll = () => {
    if (window.pageYOffset > 60) {
      setIsbg(true);
    } else {
      setIsbg(false);
    }
  };

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
    if (!isMenuActive) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
  };

  const handleNavIndex = (i) => {
    if (navActiveIndex === i) {
      setNavActiveIndex(null);
    } else {
      setNavActiveIndex(i);
    }
  };

  const navlinkList = navLinksArr.map((nav, i) => (
    <>
      <li key={navLinksArr[i].id}>
        {nav.type === "text" ? (
          <div
            onClick={() => setNavlinkIndex(i)}
            className={`nav_tab ${navlinkIndex === i ? "active" : ""} `}
          >
            {nav.linkType === "external" ? (
              <a
                className={nav.class}
                href={nav.mainLink}
                target="_blank"
                rel="noreferrer"
              >
                {nav.mainTitle}
              </a>
            ) : (
              <NavLink className={nav.class} to={nav.mainLink}>
                {nav.mainTitle}
              </NavLink>
            )}
          </div>
        ) : (
          <div
            onClick={() => setNavlinkIndex(i)}
            className={`logo_wrapper ${navlinkIndex === i ? "" : ""} `}
          >
            <a href={homeURL}>
              <img
                className={nav.class}
                onClick={scrollHandler}
                src={`${isBg ? nav.colorLogo : nav.whiteLogo}`}
                alt="upGrad living"
              />
            </a>
          </div>
        )}
      </li>
    </>
  ));

  return (
    <>
      <header className={`header_sec ${isBg ? "active" : null}`}>
        <div className="header">
          <div className="my_container">
            <div className="navlinks">
              <ul>
                {navlinkList}
                {width > 992 ? (
                  <li>
                    <div
                      className={`hamburger_lines ${
                        isMenuActive ? "active" : ""
                      } ${isBg ? "black" : ""}`}
                      onClick={handleHamburgerClick}
                    >
                      <div className="line line1"></div>
                      <div className="line line2"></div>
                      <div className="line line3"></div>
                    </div>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
          <nav className="nav_links_wrapper">
            <div className={`hamburger_menu ${isMenuActive ? "active" : ""}`}>
              <div className="row w-100 h-100 m-0">
                <div className="col-md-7 col-lg-5 p-0 mobile_menu">
                  <div className="content_box">
                    <ul className="hamburger_links">
                      {mobNavLinksArr.map((navData, i) => (
                        <HeaderNavItem
                          navData={navData}
                          key={parseInt(navData.id)}
                          arrIndex={i}
                          handleNavIndex={handleNavIndex}
                          navActiveIndex={navActiveIndex}
                        />
                      ))}
                      <li className="nav_item mobile_cta">
                        {/* <NavLink className="common_cta" to={homeURL}>
                          Virtual Tour
                        </NavLink> */}
                        <a
                          className="common_cta"
                          href="https://youtu.be/pe8Y1EIgSGY"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Virtual Tour
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-5 col-lg-7 p-0 hamburger_space">
                  <div
                    className="blank_space"
                    onClick={handleHamburgerClick}
                  ></div>
                </div>
              </div>
            </div>
          </nav>

          {/* mobile UI start */}
          {width <= 992 ? (
            <div
              className={`hamburger_lines ${isMenuActive ? "active" : ""} ${
                isBg ? "black" : ""
              }`}
              onClick={handleHamburgerClick}
            >
              <div className="line line1"></div>
              <div className="line line2"></div>
              <div className="line line3"></div>
            </div>
          ) : null}
        </div>
      </header>
    </>
  );
};

export default HeaderNew;
