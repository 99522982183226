import React, { useEffect, useRef, useState } from "react";
import "./amenities.scss";
import { amenitiesBannerData, amenitiesData } from "../../data/data";
import { amenitiesBannerVideo, amenitiesBannerVideoMb, images } from "../../source";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import AmenityCard from "../../components/AmenityCard/AmenityCard";
import { useWindowSize } from "react-use";

const Amenities = () => {
  const [showVideo, setShowVideo] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const section2Ref = useRef(null);
  const { width } = useWindowSize();

  const videoHandle = (i) => {
    setShowVideo(i);
    setIsMuted(true);
  };

  const soundHandle = () => {
    setIsMuted((prevIsMuted) => !prevIsMuted);
  };

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const amenitiesBanner = amenitiesBannerData.map((data, i) => (
    <React.Fragment key={i}>
      <div className="banner_wrap">
        <div className="video_banner">
          {/* {!showVideo && ( */}
          <div className="thumbnail_wrapper">
            <img
              width={1920}
              height={1080}
              src={width > 767 ? data.banImg : data.mbBanImg}
              alt={data.alt}
              className="banner_img"
            />
            {/* <img
                    width={95}
                    height={95}
                    src={playIcon}
                    alt="play icon"
                    className="play_icon"
                    onClick={() => videoHandle(data.id)}
                  /> */}
          </div>
          {/* )} */}
          {/* {showVideo && (
                <video
                  width={1920}
                  height={1080}
                  autoPlay
                  loop
                  muted={isMuted ? 0 : 1}
                  playsinline
                  className="w-full h-full object-cover upgrad_video"
                  onClick={soundHandle}
                >
                  <source src={data.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )} */}
        </div>
      </div>
    </React.Fragment>
  ));

  const columns = {};

  amenitiesData.forEach((data, i) => {
    if (!columns[data.column]) {
      columns[data.column] = [];
    }
    columns[data.column].push(
      <div key={data.i} data-aos="fade-up" data-aos-delay={i * 100}>
        <AmenityCard img={data.img} title={data.title} desc={data.desc} />
      </div>
    );
  });

  const amenityList = Object.keys(columns).map((columnKey) => (
    <div className={`column column-${columnKey}`} key={columnKey}>
      {columns[columnKey]}
    </div>
  ));

  const mbAmenityList = amenitiesData.map((data, i) => (
    <div data-aos="fade-up" data-aos-delay={i * 100} key={i}>
      <AmenityCard img={data.mbImg} title={data.title} desc={data.desc} />
    </div>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="amenities_sec1" data-aos="fade">
        <div className="my_container">
          <Breadcrumb text1="Amenities" disable />
          <img
            width={42}
            height={33}
            src={images.scrollDown.image}
            alt={images.scrollDown.alt}
            className="scroll_down"
            loading="lazy"
            onClick={() => scrollToRef(section2Ref)}
          />
        </div>
        <div className="banner_wrap">
          <div className="video_banner bg-white">
            <video
              width={1920}
              height={1080}
              autoPlay
              loop
              muted={isMuted ? 0 : 1}
              playsInline
              className="w-full h-full object-cover upgrad_video m-0 p-0 bg-white test"
              // onClick={soundHandle}
            >
              <source
                src={width > 600 ? amenitiesBannerVideo : amenitiesBannerVideoMb}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        {/* <div className="my_container full_width_container zero_padding">
          {amenitiesBanner}
        </div> */}
      </section>

      <div className="my_container">
        <section className="amenities_sec2" ref={section2Ref}>
          <div className="amenities_hd_sec">
            <h2 className="section_title vert_horizon_line" data-aos="fade-up">
              Luxuries Beyond <br />
              <span className="bold_text">Accommodation</span>
            </h2>
            <p
              className="section_description"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              At upGrad Living, we believe in creating a seamless and integrated
              campus experience that goes beyond traditional boundaries. Our
              amenities are designed to foster a community of learning,
              collaboration, and well-being. Discover the unique features that
              make our campus a home away from home.
            </p>
          </div>
        </section>
      </div>

      <section className="amenities_sec3">
        <div className="my_container custom_space">
          <div className="card_grid">
            {width > 600 ? amenityList : mbAmenityList}
          </div>
        </div>
      </section>

      <div className="my_container">
        <section className="amenities_sec4">
          <div className="text_container text_container1">
            <p className="section_description">
              Our integrated campus is designed to enhance every aspect of your
              living and learning experience. upGrad Living is committed to
              providing an environment where you can thrive academically,
              socially, and personally, from state-of-the-art amenities to
              comprehensive support services. Discover a place where innovation,
              community, and well-being come together to create a truly
              integrated campus experience.
            </p>
          </div>
        </section>
      </div>
    </>
  );
};

export default Amenities;
