import React from "react";
import { NavLink } from "react-router-dom";
import { images } from "../../source";

const HeaderNavItem = ({ navData }) => {
  return (
    <li className="nav_item">
      <NavLink
        className={({ isActive }) =>
          isActive ? "nav_link active" : "nav_link"
        }
        to={navData.mainLink}
        target={navData.external && "_blank"}
      >
        <img
          width={100}
          height={2}
          src={images.dashedLine.image}
          alt={images.dashedLine.alt}
          className="dashed_line"
          loading="lazy"
        />
        {navData.mainTitle}
      </NavLink>
    </li>
  );
};

export default HeaderNavItem;
