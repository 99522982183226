import React, { useEffect, useRef, useState } from "react";
import "./homepage.scss";
import {
  accommodationData,
  bannerData,
  homeAmenitiesData,
  uspData,
  vidData,
} from "../../data/data";
import { dummyVideo, images, playIcon } from "../../source";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Link } from "react-router-dom";
import { aboutURL, accommodationURL, homeURL } from "../../helpers/paths";
import { useWindowSize } from "react-use";

const Homepage = () => {
  const [showVideo, setShowVideo] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  // const [studentVideo, setStudentVideo] = useState(false);
  // const [studentMute, setStudentMute] = useState(false);
  const section2Ref = useRef(null);
  const { width } = useWindowSize();
  const aboutRef = useRef();

  const videoHandle = (i) => {
    setShowVideo(i);
    setIsMuted(true);
    // setStudentMute(false);
  };

  const soundHandle = () => {
    setIsMuted((prevIsMuted) => !prevIsMuted);
    // setStudentMute(false);
  };

  const scrollToRef = (ref, offset = 0) => {
    if (ref && ref.current) {
      const top = ref.current.offsetTop - offset;
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    }
  };

  // const studentHandle = () => {
  //   setStudentVideo(true);
  //   setStudentMute(true);
  //   setIsMuted(false);
  // };

  // const studentSoundHandle = () => {
  //   setStudentMute((prevIsMuted) => !prevIsMuted);
  //   setIsMuted(false);
  // };

  const bannerList = bannerData.map((data, i) => (
    <React.Fragment key={i}>
      <SwiperSlide>
        <div className="banner_wrap">
          {data.type === "video" ? (
            <>
              <div className="video_banner bg-white">
                {/* {showVideo !== data.id && (
                  <div className="thumbnail_wrapper">
                    <img
                      width={1920}
                      height={1080}
                      src={data.banImg}
                      alt={data.alt}
                      className="banner_img"
                    />
                    <img
                      width={95}
                      height={95}
                      src={playIcon}
                      alt="play icon"
                      className="play_icon"
                      onClick={() => videoHandle(data.id)}
                    />
                  </div>
                )}
                {showVideo === data.id && ( */}
                <video
                  width={1920}
                  height={1080}
                  autoPlay
                  loop
                  muted={isMuted ? 0 : 1}
                  playsInline
                  className="w-full h-full object-cover upgrad_video m-0 p-0 bg-white test"
                  // onClick={soundHandle}
                >
                  <source
                    src={width > 600 ? data.video : data.mbVideo}
                    type="video/mp4"
                  />
                </video>
                {/* )} */}
              </div>
            </>
          ) : (
            <img
              width={1920}
              height={1080}
              src={data.banImg}
              alt={data.alt}
              className="banner_img"
            />
          )}
        </div>
      </SwiperSlide>
    </React.Fragment>
  ));

  const row = {};

  uspData.forEach((data, i) => {
    if (!row[data.row]) {
      row[data.row] = [];
    }
    row[data.row].push(
      <div className="box" data-aos="fade-up" data-aos-delay={i * 100}>
        <div className="image_title_wrap">
          <img
            width={480}
            height={587}
            src={width > 600 ? data.img : data.mbImg}
            alt={images.why01.alt}
            className="back_img"
            loading="lazy"
          />
          <h3 className="box_title">{data.title}</h3>
        </div>
        <div className="box_content">
          <p className="box_description">{data.description}</p>
        </div>
      </div>
    );
  });

  const uspList = Object.keys(row).map((columnKey) => (
    <div className={`row-${columnKey}`} key={columnKey}>
      {row[columnKey]}
    </div>
  ));

  const vidRow = {};

  vidData.forEach((data, i) => {
    if (!vidRow[data.row]) {
      vidRow[data.row] = [];
    }
    vidRow[data.row].push(
      <div className="box" data-aos="fade-up" data-aos-delay={i * 100}>
        <div className="image_title_wrap">
          <iframe
            className="ifram_video"
            width="480"
            height="587"
            src={data.ytLink}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    );
  });

  const vidList = Object.keys(vidRow).map((columnKey) => (
    <div className={`row-${columnKey}`} key={columnKey}>
      {vidRow[columnKey]}
    </div>
  ));

  const amenitiesRow = {};

  homeAmenitiesData.forEach((data, i) => {
    if (!amenitiesRow[data.row]) {
      amenitiesRow[data.row] = [];
    }
    amenitiesRow[data.row].push(
      <div className="box" data-aos="fade-up" data-aos-delay={i * 100}>
        <div className="image_title_wrap">
          <img
            width={480}
            height={587}
            src={data.img}
            alt={images.value01.alt}
            className="back_img"
            loading="lazy"
          />
          <h3 className="box_title">{data.title}</h3>
        </div>
        <div className="box_content">
          <p className="box_description">{data.description}</p>
        </div>
      </div>
    );
  });

  const amenitiesList = Object.keys(amenitiesRow).map((columnKey) => (
    <div className={`row-${columnKey}`} key={columnKey}>
      {amenitiesRow[columnKey]}
    </div>
  ));

  const accommodationList = accommodationData.map((data, i) => (
    <React.Fragment key={i}>
      <SwiperSlide>
        <div className="accommodation_wrap">
          <img
            width={1920}
            height={1080}
            src={width > 600 ? data.heroImg : data.heroMbImg}
            alt={data.alt}
            className="accommodation_img"
            data-aos="fade-right"
          />
          <div className="accommodation_content_wrapper" data-aos="fade-up">
            <div className="accommodation_content">
              <h2 className="acc_title">{data.title}</h2>
              <ul className="highlights_list">
                {data.highlights?.map((highlights, i) => (
                  <li className="list_item" key={i}>
                    <img
                      width={30}
                      height={30}
                      src={highlights.icon}
                      alt=""
                      className="icon"
                    />
                    <p className="highlight_point">{highlights.title}</p>
                  </li>
                ))}
              </ul>
              <p
                className="acc_desc"
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
              <div className="cta_wrapper">
                {data.ctaList?.map((cta, i) => (
                  <>
                    {cta.type === "internal" ? (
                      <Link to={cta.url} className="common_cta" key={i}>
                        {cta.text}
                      </Link>
                    ) : (
                      <a
                        href={cta.url}
                        className="common_cta book_now_cta"
                        // target="_blank"
                        // rel="noreferrer"
                      >
                        {cta.text}
                      </a>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </React.Fragment>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="home_sec1" data-aos="fade">
        {width < 600 && (
          <img
            width={42}
            height={33}
            src={images.scrollDown.image}
            alt={images.scrollDown.alt}
            className="scroll_down"
            loading="lazy"
            onClick={() => scrollToRef(section2Ref, 100)}
          />
        )}
        <div className="my_container full_width_container zero_padding">
          <Swiper
            modules={[Pagination, Autoplay, Navigation]}
            pagination={{
              clickable: true,
            }}
            // autoplay={{
            //   delay: 3000,
            //   disableOnInteraction: true,
            // }}
            speed={500}
            // loop={true}
          >
            {bannerList}
          </Swiper>
        </div>
      </section>

      <div className="my_container" ref={section2Ref}>
        <section className="home_hd_sec">
          <div className="row">
            <div className="col-lg-6" data-aos="fade-up">
              <h2 className="section_title vert_horizon_line">
                Welcome to <span className="bold_text">upGrad Living</span>
              </h2>
            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <p className="section_description">
                Welcome to upGrad Living, where comfort meets convenience in the
                heart of Mumbai. Our commitment to providing a safe, secure, and
                vibrant living experience along with your campus at a proximity
                of just 10 meters sets us apart, making us the preferred choice
                for ATLAS students seeking a home away from home.
              </p>
              <div className="cta_wrapper">
                <Link to={aboutURL} className="common_cta">
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="home_sec7">
        <div className="my_container"></div>
        <div className="my_container full_width_container zero_padding">
          {vidList}
        </div>
      </section>

      <section className="home_sec3" ref={aboutRef}>
        <div className="my_container">
          <h2 className="section_title vert_horizon_line" data-aos="fade-up">
            Why {width < 600 && <br />}
            <span className="bold_text">upGrad Living</span>
          </h2>
        </div>
        <div className="my_container full_width_container zero_padding">
          {uspList}
        </div>
      </section>

      <div className="my_container">
        <section className="home_hd_sec state_container">
          <div className="row">
            <div className="col-lg-6" data-aos="fade-up">
              <h2 className="section_title vert_horizon_line">
                State of the art{" "}
                <span className="bold_text">Accommodation</span>
              </h2>
            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <p className="section_description">
                Discover your perfect space with our range of shared
                accommodation. From the camaraderie of a 3-share & 4-share room
                to the privacy of a twin share with ensuite washrooms, we have
                options to suit your preferences and budget. Reside in a top
                notch accommodation with separate floors for boys and girls
                along with 24x7 water & electricity supply.
              </p>
              <div className="cta_wrapper">
                <Link to={accommodationURL} className="common_cta">
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="home_sec4">
        <div className="my_container">
          <Swiper
            modules={[Pagination, Autoplay, Navigation]}
            pagination={{
              clickable: true,
            }}
            navigation={{
              nextEl: ".accommodation-next",
              prevEl: ".accommodation-prev",
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            speed={500}
            loop={true}
          >
            {accommodationList}
          </Swiper>
          <div className="arrows_wrapper">
            <img
              src={images.leftArrow.image}
              alt="left arrow"
              className="left_arrow accommodation-prev"
            />
            <img
              src={images.rightArrow.image}
              alt="right arrow"
              className="right_arrow accommodation-next"
            />
          </div>
        </div>
      </div>

      <section className="home_sec5">
        <div className="my_container">
          <h2 className="section_title vert_horizon_line" data-aos="fade-up">
            World Class <span className="bold_text">Amenities</span>
          </h2>
        </div>
        <div className="my_container full_width_container zero_padding">
          {amenitiesList}
          {/* <div className="row-1">
            <div className="box box-1" data-aos="fade-up">
              <div className="image_title_wrap">
                <img
                  width={480}
                  height={587}
                  src={images.amenities01.image}
                  alt={images.amenities01.alt}
                  className="back_img"
                  loading="lazy"
                />
                <div className="box_title">Gym</div>
              </div>
              <div className="box_content">
                <p className="box_description">
                  Choose from 4-share, 3-share, or twin-share rooms, all
                  maintained with the highest standards of cleanliness.
                </p>
              </div>
            </div>
            <div className="box box-2" data-aos="fade-up" data-aos-delay="100">
              <div className="image_title_wrap">
                <img
                  width={480}
                  height={587}
                  src={images.amenities02.image}
                  alt={images.amenities02.alt}
                  className="back_img"
                  loading="lazy"
                />
                <div className="box_title">Laundry Service</div>
              </div>
              <div className="box_content">
                <p className="box_description">
                  Choose from 4-share, 3-share, or twin-share rooms, all
                  maintained with the highest standards of cleanliness.
                </p>
              </div>
            </div>
            <div className="box box-3" data-aos="fade-up" data-aos-delay="200">
              <div className="image_title_wrap">
                <img
                  width={480}
                  height={587}
                  src={images.amenities03.image}
                  alt={images.amenities03.alt}
                  className="back_img"
                  loading="lazy"
                />
                <div className="box_title">Secure Wifi</div>
              </div>
              <div className="box_content">
                <p className="box_description">
                  Choose from 4-share, 3-share, or twin-share rooms, all
                  maintained with the highest standards of cleanliness.
                </p>
              </div>
            </div>
          </div>
          <div className="row-2">
            <div className="box box-1" data-aos="fade-up">
              <div className="image_title_wrap">
                <img
                  width={480}
                  height={587}
                  src={images.amenities04.image}
                  alt={images.amenities04.alt}
                  className="back_img"
                  loading="lazy"
                />
                <div className="box_title">Indoor Sports & Gaming</div>
              </div>
              <div className="box_content">
                <p className="box_description">
                  Choose from 4-share, 3-share, or twin-share rooms, all
                  maintained with the highest standards of cleanliness.
                </p>
              </div>
            </div>
            <div className="box box-2" data-aos="fade-up" data-aos-delay="100">
              <div className="image_title_wrap">
                <img
                  width={480}
                  height={587}
                  src={images.amenities05.image}
                  alt={images.amenities05.alt}
                  className="back_img"
                  loading="lazy"
                />
                <div className="box_title">
                  Security Service Round the Clock
                </div>
              </div>
              <div className="box_content">
                <p className="box_description">
                  Choose from 4-share, 3-share, or twin-share rooms, all
                  maintained with the highest standards of cleanliness.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      {/* <div className="home_sec6">
        <div className="my_container">
          <section className="home_hd_sec">
            <h2 className="section_title vert_horizon_line">
              Student <span className="bold_text">Life</span>
            </h2>
          </section>
        </div>
        <section className="life_video">
          <div className="my_container full_width_container zero_padding"> */}
          
            {/* {!studentVideo && ( */}

            {/* <div className="thumbnail_wrapper">
              <iframe
                className="student_life_youtube"
                width="100%"
                src="https://www.youtube.com/embed/X03yit90PWU?si=KoWMFKvL-MSWGiuI"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe> */}

              {/* <img
                width={1920}
                height={1080}
                src={images.life01.image}
                alt={images.life01.alt}
                className="student_thumb"
                loading="lazy"
              /> */}
              {/* <img
                  width={95}
                  height={95}
                  src={playIcon}
                  alt="play icon"
                  className="play_icon"
                  loading="lazy"
                  onClick={studentHandle}
                /> */}

            {/* </div> */}
            
            {/* )} */}
            {/* {studentVideo && (
              <video
                width={1920}
                height={1080}
                autoPlay
                loop
                muted={studentMute ? 0 : 1}
                playsinline
                className="w-full h-full object-cover students_life_video"
                onClick={studentSoundHandle}
              >
                <source src={dummyVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )} */}

          {/* </div>
        </section>
      </div> */}
    </>
  );
};

export default Homepage;
