import React from "react";
// import "./commonbanner.scss";
import styles from "./commonbanner.module.scss";
import { images } from "../../source";
import { Link } from "react-router-dom";
import { homeURL } from "../../helpers/paths";

const CommonBanner = ({
  image,
  url1,
  text1,
  text2,
  disable,
  disable2,
  title,
}) => {
  return (
    <>
      <section className={styles.combanner_sec1}>
        <div className={styles.img_wrapper}>
          <img src={image} alt="" className={styles.combanner_img} loading="lazy" />
        </div>
        <div className="my_container">
          <div className={styles.breadcrumb_wrapper}>
            <Link to={homeURL} className={styles.blink_1}>
              <img
                width={20}
                height={18}
                src={images.homeIcon.image}
                alt={images.homeIcon.alt}
                className={styles.home_icon}
                loading="lazy"
              />
            </Link>
            {text1 && (
              <Link
                to={`/${url1}`}
                className={`${styles.blink_1} ${disable && styles.noclick}`}
              >
                &nbsp;{" > "}&nbsp;
                {text1}
              </Link>
            )}
            {text2 && (
              <div
                className={`${styles.blink_1} ${disable2 && styles.noclick}`}
              >
                {" > "}
                {text2}
              </div>
            )}
          </div>
        </div>
        <div className={styles.title_wrapper}>
          <h1 className={styles.title} dangerouslySetInnerHTML={{__html: title}}></h1>
        </div>
      </section>
    </>
  );
};

export default CommonBanner;
